
import { Component, Mixins, Watch } from 'vue-property-decorator';
import ApplicationPage from '@/components/registration/ApplicationPage.vue';
import VerigramVerificationHandler from '@/components/verification/VerigramVerificationHandler.vue';

@Component({
  components: { ApplicationPage }
})
export default class RegistrationVerigram extends Mixins(VerigramVerificationHandler) {
  @Watch('verificationError')
  watchVerificationError() {
    if (this.getVerificationError()) {
      this.$notify.error(this.$t(`FORM.${this.getVerificationError()}`).toString());
    }
  }
}
