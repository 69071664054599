import { client } from '@/store/modules/client';
import { asyncMiddleware } from '@/router';
import { getRoute } from '@/router/router';

export const guest: asyncMiddleware = async () => {
  if (await client.initProfile()) {
    const route = await getRoute();
    return { name: route };
  }
};
