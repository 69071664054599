export enum UI_APPLICATION_FLOW_STEP_NAME {
  SUMMARY = 'summary',
  DECISION = 'decision',
  OFFER_DECREASE = 'decrease-offer',
  OFFER_INCREASE = 'increase-offer',
  MANUAL_VERIFICATION = 'manual-verification',
  AGREEMENT_SIGNING = 'agreement-signing',
  AGREEMENT_ONLINE_SIGNING = 'agreement-online-signing',
  INCOME_REUPLOAD = 'income_reupload'
}

export enum UI_CLIENT_FLOW_STEP_NAME {
  PERSONAL_DATA = 'personal-data',
  ADDRESS = 'address',
  FINANCES = 'finances',
  ADDITIONAL_INFO = 'additional-info',
  DISBURSEMENT_TYPE = 'disbursement-type',
  ID_DOCUMENTS = 'id-documents',
  CALCULATOR = 'calculator',
  VERIGRAM = 'verigram'
}

export enum UI_ADDITIONAL_APPLICATION_STEP_NAME {
  DISBURSEMENT_TYPE = 'update-disbursement',
  CALCULATOR = 'next-loan-calculator',
  INCOME_UPDATE = 'update-income'
}

export interface AddProgressStep {
  action: string;
  metadata?: Record<string, string | number | boolean>;
}
