import i18n from '@/core/plugins/vue-i18n';
import { content } from '@/store/modules/content';
import { SECTIONS } from '@/store/modules/models/content';

export const AddressService = new (class {
  async regionNameByKatoId(id: string) {
    await content.loadContentSection({ section: SECTIONS.REGION, locale: i18n.locale });
    const regions = content.contentSection(SECTIONS.REGION, i18n.locale);
    const region = regions ? regions[id] : null;
    if (region && typeof region === 'string') {
      return region;
    }
    return null;
  }
})();
