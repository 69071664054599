import request, { HTTP_METHODS } from '@/utils/request';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { Document, DocumentRequestList, MediaRequestDto } from '@/api/models/document';
import { stringify } from 'qs';

const PER_PAGE = 100;

export const getDocumentsList = (params?: DocumentRequestList): AxiosPromise<Document[]> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/document/documents',
    method: HTTP_METHODS.GET,
    params: { ...params, perPage: PER_PAGE },
    paramsSerializer: stringify
  });

export const getDocument = (id: string): AxiosPromise<Document> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: `/document/documents/${id}`,
    method: HTTP_METHODS.GET
  });

export const uploadDocument = (media: MediaRequestDto, options?: AxiosRequestConfig): AxiosPromise<Document> => {
  const formData = new FormData();
  formData.append('type', media.type);
  formData.append('file', media.file);
  formData.append('metadata', JSON.stringify(media.metadata));

  return request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/document/documents',
    method: HTTP_METHODS.POST,
    data: formData,
    ...options
  });
};

export const downloadDocument = (documentId: string): AxiosPromise<Blob> => {
  return request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: `/document/documents/${documentId}/download`,
    responseType: 'blob',
    method: HTTP_METHODS.GET
  });
};
