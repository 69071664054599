
import { Component, Vue } from 'vue-property-decorator';

@Component({ name: 'ContactsPage' })
export default class ContactsPage extends Vue {
  private telNumber: string = String(this.$t(`CONTACTS.SECTION_MAIN.PHONE`)).replace(/\D/g, '');
  private telLink = `tel:+${this.telNumber}`;
  private email = this.$t(`CONTACTS.SECTION_MAIN.EMAIL`).toString();
  private emailLink = `mailto:${this.email}`;
}
