
import { Component, Vue } from 'vue-property-decorator';
import LmsCalculatorLight from '@/components/calculator/CalculatorLight.vue';
import CardCalculator from '@/components/calculator/CardCalculator.vue';

@Component({
  name: 'SectionMain',
  components: { CardCalculator, LmsCalculatorLight }
})
export default class SectionMainComponent extends Vue {}
