var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"transition-container"},[_c('div',{staticClass:"transition-top",style:({
      height: _vm.top.height + 'px',
      backgroundColor: _vm.top.bg || '#ffffff'
    })}),_c('div',{staticClass:"transition-bottom",style:({
      height: _vm.bottomHeight + 'px',
      backgroundColor: _vm.bottom.bg || '#ffffff',
      'margin-top': '-' + _vm.bottomHeight + 'px',
      'border-radius': `${_vm.bottomHeight / 2}px ${_vm.bottomHeight / 2}px 0 0`
    })})])
}
var staticRenderFns = []

export { render, staticRenderFns }