import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';

export interface ProgressState {
  value: string;
}

@Module({ name: 'progress', store, dynamic: true })
export default class Progress extends VuexModule implements ProgressState {
  value = '0';

  get getValue() {
    return this.value;
  }

  @Mutation
  set(percent: string) {
    this.value = percent;
  }

  @Action
  reset() {
    this.set('0');
  }
}

export const progress = getModule(Progress);
