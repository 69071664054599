import {
  confirmVerification,
  getVerification,
  repeatVerification,
  startVerification,
  verifyVerification
} from '@/api/verification';
import {
  VERIFICATION_CHANNEL,
  VERIFICATION_CREATE_STATUS,
  VERIFICATION_REPEAT_STATUS,
  VERIFICATION_STATE,
  VerificationResponse,
  VerificationSubject,
  VerifyVerificationPayload
} from '@/api/models/verification';

import Vue from 'vue';

const prefix = 'verification_';
const craftId = (key: string) => prefix + key;

function setId(key: string, id: string): void {
  localStorage.setItem(craftId(key), id);
}

function getId(key: string): string | null {
  return localStorage.getItem(craftId(key));
}

function removeId(key: string): void {
  return localStorage.removeItem(craftId(key));
}

async function createNew<TResponse extends VerificationResponse = VerificationResponse>(
  subject: VerificationSubject,
  channel?: VERIFICATION_CHANNEL
): Promise<TResponse> {
  // Ensure companyId is always passed
  subject.attributes = { companyId: process.env.VUE_APP_BASE_COMPANY_ID, ...(subject.attributes ?? {}) };

  try {
    const response = await startVerification<TResponse>({ subject, channel });
    setId(subject.identity, response.data.id);
    return response.data;
  } catch (e) {
    if (e.status != VERIFICATION_CREATE_STATUS.VERIFICATION_ALREADY_EXIST) {
      throw e;
    }
    const verificationId = e.headers['x-current-verification-id'];
    const verification = await getVerification<TResponse>(verificationId);

    return verification.data;
  }
}

async function get<TResponse extends VerificationResponse = VerificationResponse>({
  identity
}: VerificationSubject): Promise<TResponse | null> {
  const existVerificationId = getId(identity);
  if (existVerificationId) {
    try {
      const { data: verification } = await getVerification<TResponse>(existVerificationId);
      return verification;
    } catch (e) {
      return null;
    }
  }

  return null;
}

function set({ identity }: VerificationSubject, { id }: VerificationResponse) {
  setId(identity, id);
}

function remove({ identity }: VerificationSubject): void {
  removeId(identity);
}

export type TVerifyAttributes = VerifyVerificationPayload['attributes'];

interface VerificationStateType<TResponse extends VerificationResponse = VerificationResponse> {
  payload: VerificationSubject;
  response: TResponse;
  channel?: VERIFICATION_CHANNEL;
}

export class Verification<TResponse extends VerificationResponse = VerificationResponse> {
  private state: VerificationStateType<TResponse>;

  constructor(payload: VerificationSubject, channel?: VERIFICATION_CHANNEL) {
    this.state = new Vue({
      data: { payload, channel, response: null }
    });
  }

  get _verificationResponse() {
    return this.state.response;
  }
  set _verificationResponse(response) {
    Vue.set(this.state, 'response', response);
  }

  get payload() {
    return this.state.payload;
  }
  set payload(payload) {
    Vue.set(this.state, 'payload', payload);
  }

  get channel() {
    return this.state.channel;
  }
  set channel(channel) {
    Vue.set(this.state, 'channel', channel);
  }

  get throttleReset() {
    return this._verificationResponse.throttleReset;
  }

  get response() {
    return this._verificationResponse;
  }
  set response(response) {
    this._verificationResponse = response;
  }

  setVerification(verificationResponse: TResponse) {
    this.response = verificationResponse;
  }

  get verificationId() {
    return this.response.id;
  }

  get details() {
    return this.response.details;
  }

  get isNew() {
    return this.response?.state === VERIFICATION_STATE.NEW;
  }

  get isConfirmed() {
    return this.response?.state === VERIFICATION_STATE.CONFIRMED;
  }

  get isExpired() {
    return this.response?.state === VERIFICATION_STATE.EXPIRED;
  }

  async loadVerification() {
    const verification = await get<TResponse>(this.payload);
    verification && this.setVerification(verification);
    return this.response;
  }

  async registerVerification() {
    await this.loadVerification();
    if (!this.response) {
      await this.newVerification();
    }
    return this._verificationResponse;
  }

  removeVerification() {
    remove(this.payload);
  }

  async newVerification() {
    // TODO: consider to have type-dependent approaches
    const verification = await createNew<TResponse>(this.payload, this.channel);
    this.setVerification(verification);
    set(this.payload, this.response);
  }

  async reloadVerification(): Promise<TResponse> {
    const { data: verification } = await getVerification<TResponse>(this.verificationId);
    this.setVerification(verification);
    return this.response;
  }

  async getTimer() {
    const verification = await this.reloadVerification();
    return verification.throttleReset;
  }

  async confirm(code: string) {
    await confirmVerification(this.verificationId, { code });
    return this.reloadVerification();
  }

  async verify(attributes: TVerifyAttributes) {
    await verifyVerification(this.verificationId, { attributes });
    return this.reloadVerification();
  }

  async repeat() {
    try {
      await repeatVerification(this.verificationId);
    } catch (e) {
      if (e.status === VERIFICATION_REPEAT_STATUS.IS_ALREADY_CONFIRMED_OR_EXPIRED) {
        this.removeVerification();
        await this.newVerification();
      }
    }
  }
}
