import { VerificationControllerClass } from '@/services/classes/verificationControllerClass';
import { VERIFICATION_CHANNEL, VERIFICATION_TYPE, VerificationSubject } from '@/api/models/verification';

class CoidVerificationServiceClass extends VerificationControllerClass {
  subject: VerificationSubject;
  channel: VERIFICATION_CHANNEL.COID;

  async initVerification(identity: string, mediaId: string) {
    this.subject = {
      identity: identity,
      type: VERIFICATION_TYPE.IDENTITY_CONFIRMATION,
      attributes: { mediaId: mediaId }
    };

    return await this.createVerification(this.subject, VERIFICATION_CHANNEL.COID);
  }

  async verify() {
    if (!this.verificationStore) {
      return;
    }

    const verificationId = this.verificationStore.verificationId;
    const verification = await this.restoreVerification(verificationId, VERIFICATION_CHANNEL.COID);

    if (!verification) {
      return;
    }

    const canBeConfirmed = !verification?.isConfirmed && !verification?.isExpired;
    if (canBeConfirmed) {
      await verification.verify({});
    }
    this.verificationStore.removeVerification();
  }
}

export const CoidVerificationService = new CoidVerificationServiceClass();
