export const enum IDENTITY_DOCUMENT_TYPE {
  PASSPORT = 'passport',
  NATIONAL_ID = 'national_id'
}

export type IdentityDocument = {
  type: IDENTITY_DOCUMENT_TYPE;

  current: boolean;
  number: string;
  issuedAt: string;
  expiresAt: string;
  issuedBy: string | null;
};
