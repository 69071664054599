import { FieldValue } from '@/components/registration/models/field';
import { stringifyFieldValue } from './helpers';
import { CURRENCY_SYMBOL } from '@/core/config/setup/product';

export const moneyPattern = (value: FieldValue): number => {
  return (
    Number.parseInt(
      stringifyFieldValue(value)
        .replace(CURRENCY_SYMBOL, '')
        .split(',')
        .join('')
    ) || 0
  );
};

export const noSpacePattern = (value: FieldValue): string => {
  return stringifyFieldValue(value).replace(/\s/g, '');
};

export const toUppercasePattern = (value: FieldValue): string => {
  return stringifyFieldValue(value).toLocaleUpperCase();
};

export const ibanLength = 20;
export const ibanPrefix = 'KZ';
export const bankAccountPattern = (value: FieldValue): string => {
  value = noSpacePattern(value);
  return value.length > 2 ? value : ibanPrefix;
};
