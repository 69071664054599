
import { Vue, Component } from 'vue-property-decorator';
import { progress } from '@/store/modules/progress';

@Component
export default class ProgressBar extends Vue {
  get filled() {
    return { width: `${progress.getValue}%` };
  }
}
