type ImageGridCellModel = {
  img: string;
  legend?: string;
};

export type GridImages = Array<ImageGridCellModel>;

export const enum GRID_ROW_TYPE {
  PARAGRAPH = 'PARAGRAPH',
  IMAGES = 'IMAGES'
}

type GridRowImages = {
  TYPE: GRID_ROW_TYPE.IMAGES;
  ROW: GridImages;
};
type GridRowParagraph = {
  TYPE?: GRID_ROW_TYPE.PARAGRAPH;
  ROW: string;
};
export type GridRow = GridRowImages | GridRowParagraph;
