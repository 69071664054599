
import { Vue, Component } from 'vue-property-decorator';
import { ROUTE } from '@/router/routes_type';
import { clearRoute, getRoute, storeRoute } from '@/router/router';
import { loader } from '@/utils/loader';
import router from '@/router';
import { Dictionary } from 'vue-router/types/router';

@Component
export default class CheckRoutingMixin extends Vue {
  protected async checkRouteBeforeProgress(route: ROUTE): Promise<ROUTE | null> {
    const actualRoute = await getRoute();

    return actualRoute === route ? null : actualRoute;
  }

  protected async redirect(route: ROUTE, params?: Dictionary<string>, store = false): Promise<void> {
    await loader.show();
    if (store) {
      storeRoute(route);
    } else {
      clearRoute();
    }
    await router.push({ name: route, params: params });
  }
}
