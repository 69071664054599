
import { Vue, Component } from 'vue-property-decorator';
import { client } from '@/store/modules/client';

@Component({
  name: 'ProfileNavBarLogOut'
})
export default class ProfileNavBarLogOut extends Vue {
  client = client;
}
