
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Location } from 'vue-router';

@Component({
  name: 'ProfileNavBarLink'
})
export default class ProfileNavBarLink extends Vue {
  @Prop({ required: true }) route!: Location;

  @Prop({ default: false }) active!: boolean;

  @Prop({ required: false }) disabled!: boolean;
}
