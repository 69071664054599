
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { content } from '@/store/modules/content';
import { SECTIONS } from '@/store/modules/models/content';
import i18n from '@/core/plugins/vue-i18n';

@Component({
  name: 'Faq'
})
export default class Faq extends Vue {
  @Prop({ required: true })
  section: SECTIONS;
  private activeItem: number | null = null;

  async created() {
    await this.loadOptions();
  }

  get items() {
    return content.contentSection(this.section, i18n.locale);
  }

  @Watch('$i18n.locale', { immediate: true })
  async loadOptions() {
    const sections = content.contentSection(this.section, i18n.locale);
    if (!sections) {
      await content.loadContentSection({ section: this.section, locale: i18n.locale });
    }
  }
}
