
import { Vue, Component, Watch } from 'vue-property-decorator';
import SectionQuestionnaire from '@/components/layout/sections/SectionQuestionnaire.vue';
import { getNews } from '@/views/News/setting';
import { Article } from '@/components/NewsArticle/types';
import NewsArticle from '@/components/NewsArticle/index.vue';
import BackButton from '@/components/BackButton/index.vue';
import { LOCALES } from '@/api/models/common';

@Component({
  name: 'NewsPage',
  components: { BackButton, NewsArticle, SectionQuestionnaire }
})
export default class NewsPage extends Vue {
  loaded = false;
  news: Article[] = [];

  mounted() {
    this.loadNews();
  }

  get displayNews() {
    return this.news.filter((article: Article) => !article.hidden);
  }

  @Watch('$i18n.locale')
  async loadNews() {
    this.news = await getNews(this.$i18n.locale as LOCALES);
    this.loaded = true;
  }
}
