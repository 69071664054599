import { asyncMiddleware } from '@/router';
import { PROFILE_ROUTES } from '@/router/routes_type';
import { client } from '@/store/modules/client';
import { applications } from '@/store/modules/application';
import { APPLICATION_STATUS } from '@/api/models/application';
import { getAgreementList } from '@/api/agreements';
import { AGREEMENT_PROCESSING_STATUS } from '@/api/models/agreements';
import { agreements } from '@/store/modules/agreements';
import { getLoanDpdEntry } from '@/api/lending';
import { debtSettlementApplication } from '@/store/modules/debtSettlementApplication';
import { DebtSettlementApplicationStatus, FINAL_STATUSES } from '@/api/models/debtSettlementApplication';

export const initLoanMiddleware: asyncMiddleware = async () => {
  if (!(await agreements.initGetAgreementList())) {
    return { name: PROFILE_ROUTES.NO_LOAN_PAGE };
  }

  try {
    await client.initProfileDisbursements();
  } catch {
    return;
  }
};

export const initDebtSettlementApplicationsMiddleware: asyncMiddleware = async () => {
  if (!(await debtSettlementApplication.initGetDebtSettlementApplications())) {
    return { name: PROFILE_ROUTES.MY_LOAN_PAGE };
  }

  return;
};

export const loanInDebtMiddleware: asyncMiddleware = async ({ to }) => {
  const agreement = agreements.getAgreements.find(a => a.id === to.params.agreementId);

  if (!agreement) {
    return { name: PROFILE_ROUTES.MY_LOAN_PAGE };
  }

  try {
    const { data: dpd } = await getLoanDpdEntry(agreement.id);
    if (dpd.dpd > 0) {
      return;
    }
  } catch {
    return { name: PROFILE_ROUTES.MY_LOAN_PAGE };
  }

  return { name: PROFILE_ROUTES.MY_LOAN_PAGE };
};

export const activeDebtSettlementApplication: asyncMiddleware = async ({ to }) => {
  const application = debtSettlementApplication.getDebtSettlementApplications.find(a => a.id === to.params.id);

  if (!application || FINAL_STATUSES.includes(application.status)) {
    return { name: PROFILE_ROUTES.MY_LOAN_PAGE };
  }

  return;
};

export const hasDebtSettlementApplicationsMiddleware: asyncMiddleware = async () => {
  if (debtSettlementApplication.getDebtSettlementApplications.length > 0) {
    return;
  }

  return { name: PROFILE_ROUTES.MY_LOAN_PAGE };
};

export const noActiveAgreementsMiddleware: asyncMiddleware = async () => {
  await applications.initGetApplications();

  const approvedApplications = applications.getApplications.filter(application => {
    return application.status === APPLICATION_STATUS.APPROVED;
  });

  for (const application of approvedApplications) {
    const { data: agreements } = await getAgreementList({ applicationId: application.id });
    const hasAgreements = agreements.length > 0;
    const hasActiveAgreement = agreements.some(agreement => {
      return agreement.processingStatus !== AGREEMENT_PROCESSING_STATUS.CLOSED;
    });

    if (!hasAgreements || hasActiveAgreement) {
      return { name: PROFILE_ROUTES.MY_LOAN_PAGE };
    }
  }
};
