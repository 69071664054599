
import { Component, Prop, Vue } from 'vue-property-decorator';
import LogoColor from '@/assets/img/icons/logo-white.svg';
import LogoBlack from '@/assets/img/icons/logo-black.svg';

@Component({
  name: 'Logo'
})
export default class NavigationComponent extends Vue {
  @Prop({ default: true }) private color: boolean;

  get logoUrl() {
    return this.color ? LogoColor : LogoBlack;
  }
}
