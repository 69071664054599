import { FieldValue } from '@/components/registration/models/field';
import { ibanLength, ibanPrefix, noSpacePattern } from '@/components/registration/settings/pattern';
import { stringifyFieldValue } from '@/components/registration/settings/helpers';
import { CURRENCY_SYMBOL } from '@/core/config/setup/product';
import { createNumberMask } from 'text-mask-addons';

export type MaskValue = RegExp | string;
export type MaskFunctionValue = Array<MaskValue>;
export type MaskFunction = (value: string) => MaskFunctionValue;
export type TMask = MaskFunctionValue | MaskFunction | MaskValue;

export function applyMask(value: string, pattern: string) {
  let i = 0;
  return pattern.replace(/\S/g, () => value[i++]);
}

export const mobilePhoneMask = '+7 ### ### ## ##';

export const phoneVerificationMask = '######';

export const emailVerificationMask = '######';

const defaultDateMask = '##/##/####';
const dateLength = defaultDateMask.length;
const digit = /[0-9]/;
const mixed = /[0-9A-Z]/;

export const dateMask = (value: FieldValue | null): TMask => {
  const safeVaue = value ? value.toString() : '';
  const onlyDateNumbers = noSpacePattern(safeVaue.substr(0, dateLength)).replace(/[^0-9.]/g, '');

  if (onlyDateNumbers && onlyDateNumbers.length) {
    const day2 = onlyDateNumbers.charAt(0) === '3' ? /[0-1]/ : digit;
    const month2 = onlyDateNumbers.charAt(2) === '1' ? /[0-2]/ : digit;
    const days = [/[0-3]/, day2];
    const month = [/[0-1]/, month2];

    const year2 = onlyDateNumbers.charAt(4) === '1' ? /9/ : /0/;
    const year = [/[1,2]/, year2, digit, digit];
    const dd = [...days];
    const ddmm = [...dd, '/', ...month];
    const ddmmYYYY = [...ddmm, '/', ...year];

    return onlyDateNumbers.length > 4 ? ddmmYYYY : onlyDateNumbers.length > 2 ? ddmm : dd;
  } else {
    return defaultDateMask;
  }
};

const iinLength = 12;
export const iinMask = (value: FieldValue): Array<string> => {
  const iinDraft = stringifyFieldValue(value).substr(0, iinLength * 2 - 1);
  const str12numbers = noSpacePattern(iinDraft).replace(/[^0-9.]/g, '');
  const iin = str12numbers.split('').join(' ');
  return [iin];
};

export const cyrillicMask: MaskFunction = value => {
  const languageRegex = /[\u0400-\u04FF-]/;
  const mask = [languageRegex];

  for (let i = 0; i <= value.length; i++) {
    mask[i] = languageRegex;
  }

  return mask;
};

export const alphaNumericMask: MaskFunction = value => {
  const alphaNumericRegex = /[\u0400-\u04FF-0-9()"«»., ]/;
  const mask = [alphaNumericRegex];

  for (let i = 0; i <= value.length; i++) {
    mask[i] = alphaNumericRegex;
  }

  return mask;
};

export const companyMask: MaskFunction = value => {
  const alphaNumericRegex = /[\u0400-\u04FF-a-zA-Z0-9 ]/;
  const mask = [alphaNumericRegex];

  for (let i = 0; i <= value.length; i++) {
    mask[i] = alphaNumericRegex;
  }

  return mask;
};

export const passportMask = '#########';

export const houseApartmentNumberMask = '#DDDDDDD';

export const currencyMask = createNumberMask({
  prefix: CURRENCY_SYMBOL,
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false
});

// https://wise.com/gb/iban/kazakhstan
const ibanKZmask = [/K/, /Z/, ...Array(5).fill(digit), ...Array(13).fill(mixed)];
const regex = new RegExp(`^(${ibanPrefix})+`, '');

export const bankAccountMask: MaskFunction = value => {
  const draft = stringifyFieldValue(value);
  const text = noSpacePattern(draft).replace(regex, '');
  const str = ibanPrefix + text.toUpperCase().substr(0, ibanLength * 2 - 1);

  const masked = ibanKZmask
    .map((pattern, idx) => (str[idx] && pattern.test(str[idx]) ? str[idx] : ''))
    .filter(c => !!c)
    .join(' ');

  return [masked];
};
