
import { Component, Mixins } from 'vue-property-decorator';
import { VerigramVerificationService } from '@/services/verigram';
import { client } from '@/store/modules/client';
import { FORM_ERRORS } from '@/components/registration/settings/rules';
import { loader } from '@/utils/loader';
import router from '@/router';
import { REGISTRATION_ROUTES } from '@/router/routes_type';
import { SEND_PROGRESS } from '@/router/routes';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';
import { CoidVerificationService } from '@/services/coid';

interface VerigramVerificationState {
  getVerificationLoading: () => boolean;
  getVerificationError: () => string;
}

@Component({
  name: 'VerigramVerificationHandler'
})
export default class VerigramVerificationHandler extends Mixins(CheckRoutingMixin)
  implements VerigramVerificationState {
  private verigramVerificationService = VerigramVerificationService;
  private coidVerificationService = CoidVerificationService;
  public verificationError = '';
  public verificationLoading = false;

  async startVerification() {
    this.verificationError = '';
    this.verificationLoading = true;

    const route = await this.checkRouteBeforeProgress(REGISTRATION_ROUTES.VERIGRAM);
    if (route) await this.redirect(route, { SEND_PROGRESS: SEND_PROGRESS });

    try {
      await this.verigramVerificationService.initVerification(client.username);
    } catch (data) {
      this.verificationError = FORM_ERRORS.FIELD_ERROR_VERIFICATION_CREATE;
    } finally {
      this.verificationLoading = false;
    }
  }

  async detectVerification() {
    try {
      await this.verigramVerificationService.tryVerifyVerification(this.$route);
    } catch (data) {
      this.verificationError = FORM_ERRORS.FIELD_ERROR_VERIFICATION_RESTORE_FAILED;
      return;
    }

    if (!this.verigramVerificationService.state?.value?.details?.bestFrameMediaId) {
      return;
    }

    try {
      await this.coidVerificationService.initVerification(
        client.username,
        this.verigramVerificationService.state?.value?.details?.bestFrameMediaId
      );
    } catch (data) {
      this.verificationError = FORM_ERRORS.FIELD_ERROR_VERIFICATION_CREATE;
      return;
    }

    try {
      await this.coidVerificationService.verify();
    } catch (data) {
      if (data.status === 400) {
        this.verificationError = FORM_ERRORS.COID_VERIFICATION_FAILED;
      } else if (data.status === 409) {
        await loader.show();
        await this.tryRedirect();
      } else if (data.status === 410) {
        this.verificationError = FORM_ERRORS.FIELD_ERROR_CONFIRM_AMOUNT_410;
      }
    }
  }

  async created() {
    await loader.show();
    await this.detectVerification();
    await this.tryRedirect();
  }

  async tryRedirect() {
    setTimeout(async () => {
      await client.initProfile({ force: true });

      if (
        client.livelinessVerificationReject ||
        (client.livelinessVerificationConfirmed && client.usernameVerificationFinished)
      ) {
        await router.push({ name: REGISTRATION_ROUTES.ADDITIONAL_INFO, params: { SEND_PROGRESS: SEND_PROGRESS } });
      }

      loader.close();
    }, 5_000);
  }

  getVerificationLoading() {
    return this.verificationLoading;
  }

  getVerificationError() {
    return this.verificationError;
  }
}
