import Vue from 'vue';
import Vuex from 'vuex';
import { ApplicationState } from '@/store/modules/models/application';
import { calculator } from '@/store/modules/calculator';
import { ClientState } from '@/store/modules/models/client';
import { ContentState } from '@/store/modules/models/content';
import { IovationState } from '@/store/modules/iovation';
import { ProgressState } from '@/store/modules/progress';
import { schedule } from '@/store/modules/schedule';

Vue.use(Vuex);

export interface RootStateInterface {
  client: ClientState;
  content: ContentState;
  calculator: typeof calculator;
  schedule: typeof schedule;
  iovation: IovationState;
  progress: ProgressState;
  applications: ApplicationState;
}

export interface Payload {
  force: boolean;
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<RootStateInterface>({});
