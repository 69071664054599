import { loader } from '@/utils/loader';

import { VerificationControllerClass } from '@/services/classes/verificationControllerClass';
import {
  VERIFICATION_CHANNEL,
  VERIFICATION_TYPE,
  VerificationResponse,
  VerificationSubject
} from '@/api/models/verification';
import { Route } from 'vue-router';
import i18n from '@/core/plugins/vue-i18n';
import { LOCALES } from '@/api/models/common';

export enum VERIFICATION_QUERY {
  ID = 'verificationId'
}

type VerigramVerificationAttributes = {
  redirectUrl: string;
};

export type VerigramVerificationState = VerificationResponse & {
  receiptAttributes: VerigramVerificationAttributes | null;
};

class VerigramVerificationServiceClass extends VerificationControllerClass<VerigramVerificationState> {
  subject: VerificationSubject;
  channel: VERIFICATION_CHANNEL.VERIGRAM;

  async initVerification(identity: string) {
    this.subject = {
      identity: identity,
      type: VERIFICATION_TYPE.LIVELINESS_CONFIRMATION,
      attributes: {
        companyId: process.env.VUE_APP_BASE_COMPANY_ID,
        locale: i18n.locale === LOCALES.RU ? 'ru' : 'kz'
      }
    };

    await this.createVerification(this.subject, VERIFICATION_CHANNEL.VERIGRAM);
    const redirectTo = this.verificationStore?.response.receiptAttributes?.redirectUrl;
    if (redirectTo) {
      await loader.show();
      window.location.href = redirectTo;
    }

    return this.verificationStore;
  }

  removeVerification() {
    this.verificationStore && this.verificationStore.removeVerification();
    this.destroyVerification();
  }

  catchVerificationId(route: Route) {
    return route.params.verificationId || (route.query[VERIFICATION_QUERY.ID] as string);
  }

  async tryVerifyVerification(route: Route) {
    this.removeVerification();
    const verificationId = this.catchVerificationId(route);

    if (verificationId) {
      const verification = await this.restoreVerification(verificationId, VERIFICATION_CHANNEL.VERIGRAM);

      if (!verification) {
        return false;
      }

      const canBeConfirmed = !verification?.isConfirmed && !verification?.isExpired;
      if (canBeConfirmed) {
        return await verification.verify({});
      }
    }

    return Boolean(verificationId);
  }
}

export const VerigramVerificationService = new VerigramVerificationServiceClass();
