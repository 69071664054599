
import { Component, Vue } from 'vue-property-decorator';
import { APPLICATION_ROUTES } from '@/router/routes_type';

@Component({
  name: 'SectionQuestionnaire'
})
export default class SectionQuestionnaireComponent extends Vue {
  loginPage = { name: APPLICATION_ROUTES.LOGIN_PAGE };
}
