import Cookies from 'js-cookie';
import { QueryParams } from '@/router/guards';

const METRICS_KEY = 'app_marketing';
const AFFILIATE_COOKIE_TTL = 4320; // 72 hours

export const marketingMetrics = () => {
  let result = {};
  const metricsCookies = Cookies.get(METRICS_KEY);

  if (!metricsCookies) {
    return result;
  }

  try {
    result = JSON.parse(metricsCookies);
  } catch (e) {
    return result;
  }

  return result;
};

const toDays = (minutes: number) => minutes / 24 / 60;

export const storeMarketingMetrics = (metrics: QueryParams) => {
  if (Cookies.get(METRICS_KEY)) {
    return;
  }

  const ttl = toDays(AFFILIATE_COOKIE_TTL);
  Cookies.set(METRICS_KEY, JSON.stringify(metrics), { expires: ttl });
};

export const cleanUpMarketingMetrics = () => {
  Cookies.remove(METRICS_KEY);
};
