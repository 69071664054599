import Vue from 'vue';
import { CURRENCY_SYMBOL, DATE_PATTERN_TO_SHOW } from '@/core/config/setup/product';
import dayjs from 'dayjs';

type Filter = (value: string | number) => string;

const reverseString = (v: string) =>
  v
    .split('')
    .reverse()
    .join('');

const formatMoney = (value: string) => {
  const dotIndex = value.indexOf('.');
  const reversed = reverseString(dotIndex < 0 ? value : value.substring(0, dotIndex));

  return reverseString(reversed.replace(/\d{3}/g, '$& '));
};

export const roundFloor: Filter = value => {
  if (!value) {
    return '';
  }
  return String(Math.floor(Number(value)));
};

export const money: Filter = value => {
  if (!value) {
    return '';
  }

  return `${formatMoney(value.toString())} ${CURRENCY_SYMBOL}`;
};

export const noCurrencyMoney: Filter = value => (value ? formatMoney(value.toString()) : '');

export const formatDate = (value: string): string => {
  if (!value) {
    return 'N/A';
  }
  return dayjs(value).format(DATE_PATTERN_TO_SHOW);
};

Vue.filter('roundFloor', roundFloor);
Vue.filter('money', money);
Vue.filter('noCurrencyMoney', noCurrencyMoney);
Vue.filter('formatDate', formatDate);
