import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store, { Payload } from '@/store';
import {
  DebtSettlementApplicationInput,
  DebtSettlementApplicationTransitionInput
} from '@/api/models/debtSettlementApplication';
import { createApplication, getApplications, transitApplication } from '@/api/debtSettlementApplication';
import { DebtSettlementApplication as DebtSettlementApplicationModel } from '@/api/models/debtSettlementApplication';

@Module({ name: 'debtSettlementApplication', store, dynamic: true })
class DebtSettlementApplication extends VuexModule {
  private loaded = false;
  private applications: DebtSettlementApplicationModel[] = [];

  get getDebtSettlementApplications() {
    return this.applications;
  }

  get lastDebtSettlementApplication(): DebtSettlementApplicationModel | null {
    return this.applications.length > 0 ? this.applications[0] : null;
  }

  @Mutation
  setDebtSettlementApplications(applications: DebtSettlementApplicationModel[]) {
    this.applications = applications;
  }

  @Mutation
  addDebtSettlementApplication(application: DebtSettlementApplicationModel) {
    this.applications.unshift(application);
  }

  @Mutation
  updateDebtSettlementApplication(application: DebtSettlementApplicationModel) {
    this.applications = this.applications.filter(a => a.id !== application.id);
    this.applications.unshift(application);
  }

  @Action({ rawError: true })
  async initCreateDebtSettlementApplication(payload: DebtSettlementApplicationInput) {
    const { data } = await createApplication(payload);
    this.addDebtSettlementApplication(data);

    return true;
  }

  @Action({ rawError: true })
  async initTransitDebtSettlementApplication(input: DebtSettlementApplicationTransitionInput) {
    const { data } = await transitApplication(input.id, input.transition);
    this.updateDebtSettlementApplication(data);

    return true;
  }

  @Action
  async initGetDebtSettlementApplications(context: Payload = { force: false }) {
    if (this.loaded && !context.force) {
      return true;
    }

    try {
      const { data } = await getApplications();

      this.setDebtSettlementApplications(data);
      this.setLoadedApplications(true);

      return true;
    } catch (e) {
      return false;
    }
  }

  @Action
  reset() {
    this.setDebtSettlementApplications([]);
    this.setLoadedApplications(false);
  }

  @Mutation
  private setLoadedApplications(loaded: boolean) {
    this.loaded = loaded;
  }
}

export const debtSettlementApplication = getModule(DebtSettlementApplication);
