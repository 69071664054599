import request, { HTTP_METHODS } from '@/utils/request';
import {
  AddProgressStep,
  UI_CLIENT_FLOW_STEP_NAME,
  UI_APPLICATION_FLOW_STEP_NAME,
  UI_ADDITIONAL_APPLICATION_STEP_NAME
} from '@/api/models/progress';
import { AxiosPromise } from 'axios';
import { MULTI_LOAN_ROUTES, REGISTRATION_ROUTES } from '@/router/routes_type';
import { applications } from '@/store/modules/application';
import { client } from '@/store/modules/client';

export const addStep = (data: AddProgressStep): AxiosPromise<void> | undefined => {
  try {
    request({
      baseURL: process.env.VUE_APP_BASE_API_AUTH,
      url: '/kt-progress/steps',
      method: HTTP_METHODS.POST,
      data
    });
  } catch (e) {
    return;
  }
};

export function buildUiStepState(name: string, metadata?: AddProgressStep['metadata']): AddProgressStep {
  return { action: name, metadata: { ...metadata, uiState: true } };
}

async function buildNextApplicationUiStepState(name: string): Promise<AddProgressStep> {
  return buildUiStepState(name, { desiredLoanHash: await hash(JSON.stringify(client.desiredLoan)) });
}

async function buildApplicationUiStepState(name: string): Promise<AddProgressStep> {
  if (applications.getActiveApplication?.id) {
    return buildUiStepState(name, { applicationId: applications.getActiveApplication.id });
  }

  await applications.initGetApplications();

  return applications.getActiveApplication?.id
    ? buildUiStepState(name, { applicationId: applications.getActiveApplication.id })
    : buildUiStepState(name);
}

export async function buildUiStepStateBySlugRoute(name: string): Promise<AddProgressStep | undefined> {
  const action = actionByRoute(name);

  if (action) {
    if ((<any>Object).values(UI_CLIENT_FLOW_STEP_NAME).includes(action)) {
      return buildUiStepState(action);
    }
    if ((<any>Object).values(UI_ADDITIONAL_APPLICATION_STEP_NAME).includes(action)) {
      return buildNextApplicationUiStepState(action);
    }

    return buildApplicationUiStepState(action);
  }
}

function actionByRoute(route: string): string | null {
  switch (route) {
    case MULTI_LOAN_ROUTES.CALCULATOR:
      return UI_ADDITIONAL_APPLICATION_STEP_NAME.CALCULATOR;
    case REGISTRATION_ROUTES.CALCULATOR:
      return UI_CLIENT_FLOW_STEP_NAME.CALCULATOR;
    case REGISTRATION_ROUTES.SUMMARY_PAGE:
    case MULTI_LOAN_ROUTES.SUMMARY_PAGE:
      return UI_APPLICATION_FLOW_STEP_NAME.SUMMARY;
    case MULTI_LOAN_ROUTES.INCOME_PAGE:
      return UI_ADDITIONAL_APPLICATION_STEP_NAME.INCOME_UPDATE;
    case REGISTRATION_ROUTES.DISBURSEMENT_TYPE_PAGE:
      return UI_CLIENT_FLOW_STEP_NAME.DISBURSEMENT_TYPE;
    case MULTI_LOAN_ROUTES.DISBURSEMENT_TYPE_PAGE:
      return UI_ADDITIONAL_APPLICATION_STEP_NAME.DISBURSEMENT_TYPE;
    case REGISTRATION_ROUTES.DOCUMENTS_PAGE:
      return UI_CLIENT_FLOW_STEP_NAME.ID_DOCUMENTS;
    case REGISTRATION_ROUTES.DECISION_PAGE:
      return UI_APPLICATION_FLOW_STEP_NAME.DECISION;
    case REGISTRATION_ROUTES.INCOME_REUPLOAD_PAGE:
      return UI_APPLICATION_FLOW_STEP_NAME.INCOME_REUPLOAD;
    case REGISTRATION_ROUTES.OFFER_DECREASE_PAGE:
      return UI_APPLICATION_FLOW_STEP_NAME.OFFER_DECREASE;
    case REGISTRATION_ROUTES.OFFER_INCREASE_PAGE:
      return UI_APPLICATION_FLOW_STEP_NAME.OFFER_INCREASE;
    case REGISTRATION_ROUTES.MANUAL_VERIFICATION_PAGE:
      return UI_APPLICATION_FLOW_STEP_NAME.MANUAL_VERIFICATION;
    case REGISTRATION_ROUTES.AGREEMENT_SIGNING_PAGE:
      return UI_APPLICATION_FLOW_STEP_NAME.AGREEMENT_SIGNING;
    case REGISTRATION_ROUTES.AGREEMENT_ONLINE_SIGNING_PAGE:
      return UI_APPLICATION_FLOW_STEP_NAME.AGREEMENT_ONLINE_SIGNING;
    case REGISTRATION_ROUTES.VERIGRAM:
      return UI_CLIENT_FLOW_STEP_NAME.VERIGRAM;
    default:
      return null;
  }
}

async function hash(value: string) {
  const utf8 = new TextEncoder().encode(value);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(bytes => bytes.toString(16).padStart(2, '0')).join('');
}
