export enum ADDRESS_TYPE {
  LIVING = 'living',
  DECLARED = 'declared'
}

export type AddressFields = {
  house: string;
  apartment: string;
  region: string;
  regionKatoId: string;
};

export type Address = {
  type: ADDRESS_TYPE;
  city: string;
  street: string;
  fields: AddressFields;
};
