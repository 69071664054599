import { Input } from '@/components/registration/models/input';
import { idNumberFormat, numeric, passwordFormat, required } from '@/components/registration/settings/rules';
import { noSpacePattern } from '@/components/registration/settings/pattern';
import { iinMask } from '@/components/registration/settings/mask';

export const loginData: Input[] = [
  {
    name: 'username',
    setting: {
      tooltip: true,
      type: 'text',
      mask: iinMask,
      pattern: noSpacePattern
    },
    autocomplete: 'username',
    rules: { required, numeric, idNumberFormat }
  },
  {
    name: 'password',
    setting: {
      mask: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
      type: 'password'
    },
    autocomplete: 'current-password',
    rules: { required, passwordFormat }
  }
];
