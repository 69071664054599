
import { Component, Vue } from 'vue-property-decorator';
import { SECTIONS } from '@/store/modules/models/content';
import Faq from '@/components/Faq.vue';

@Component({
  name: 'SectionFaq',
  components: { Faq },
  computed: {
    SECTIONS_FAQ() {
      return SECTIONS.FAQ;
    }
  }
})
export default class SectionFaqComponent extends Vue {}
