import Cookies from 'js-cookie';
import { LOCALES } from '@/api/models/common';

// User
const accessTokenKey = 'jwt_access_token';
export const getAccessToken = () => Cookies.get(accessTokenKey);
export const setAccessToken = (token: string) => Cookies.set(accessTokenKey, token);

const refreshTokenKey = 'jwt_refresh_token';
export const getRefreshToken = () => Cookies.get(refreshTokenKey);
export const setRefreshToken = (token: string) => Cookies.set(refreshTokenKey, token);

// Calculator
const calculatorAmountKey = 'calculator_amount';
const calculatorTermKey = 'calculator_term';
export const getCalculatorAmount = () => {
  const amount = Cookies.get(calculatorAmountKey);

  return amount ? parseInt(amount, 10) : null;
};
export const setCalculatorAmount = (amount: number) => Cookies.set(calculatorAmountKey, amount.toString());

export const getCalculatorTerm = () => {
  const term = Cookies.get(calculatorTermKey);

  return term ? parseInt(term, 10) : null;
};
export const setCalculatorTerm = (term: number) => Cookies.set(calculatorTermKey, term.toString());

// Locale
const LOCALE_KEY = 'language';
export const setLocale = (locale: LOCALES) => Cookies.set(LOCALE_KEY, locale);
const localeFromCookie = Cookies.get(LOCALE_KEY) as LOCALES;
export const CURRENT_LOCALE: LOCALES = Object.values(LOCALES).includes(localeFromCookie)
  ? localeFromCookie
  : LOCALES.RU;
