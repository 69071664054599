import request, { HTTP_METHODS } from '@/utils/request';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { ScheduleResponse, Product } from './models/product';

export const getProduct = (alias: string): AxiosPromise<Product> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_ANON,
    url: `/kt-product/products/${alias}/parameters`,
    method: HTTP_METHODS.GET
  });

interface ScheduleRequest {
  alias: string;
  amount: string;
  conditions: {
    term: number;
    startDate: string;
    paymentDayOfMonth: number;
  };
}

export const getProductSchedule = (
  params: ScheduleRequest,
  options?: AxiosRequestConfig
): AxiosPromise<ScheduleResponse> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_ANON,
    url: `/kt-product/calculator/schedule/instalment`,
    method: HTTP_METHODS.POST,
    data: params,
    ...options
  });
