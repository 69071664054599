import { downloadDocument } from '@/api/document';
import { CONTENT_FILENAME, HEADERS, ResponseHeaders } from '@/utils/request';
import { getExtension, MIME } from '@/utils/file';
import { trim, uniq } from 'lodash-es';
import {
  Document,
  DOCUMENT_TYPES,
  ID_DOCUMENT_TYPES,
  OTHER_DOCUMENT_TYPES,
  VERIGRAM_DOCUMENT_TYPES
} from '@/api/models/document';
import { documents } from '@/store/modules/documents';

class DocumentServiceClass {
  async download(documentId: string) {
    const { data: downloadedDocument, headers } = await downloadDocument(documentId);

    this.createDownloadLink(downloadedDocument, headers);
  }

  async open(documentId: string) {
    const { data: downloadedDocument, headers } = await downloadDocument(documentId);

    const link = URL.createObjectURL(downloadedDocument);
    const opened = window.open(link, '_blank');

    if (!opened) {
      this.createDownloadLink(downloadedDocument, headers);
    }

    URL.revokeObjectURL(link);
  }

  getPersonDocuments(): Document[] {
    const types: DOCUMENT_TYPES[] = Object.values(ID_DOCUMENT_TYPES);
    types.push(OTHER_DOCUMENT_TYPES.SELFIE);

    return this.getDocumentsByTypes(types);
  }

  hasIdDocuments() {
    const types: DOCUMENT_TYPES[] = Object.values(ID_DOCUMENT_TYPES);

    return uniq(this.getDocumentsByTypes(types).map(d => d.type)).length === types.length;
  }

  hasBankStatement() {
    return this.getDocumentsByTypes([OTHER_DOCUMENT_TYPES.BANK_STATEMENT]).length > 0;
  }

  hasSelfie() {
    return this.getPersonDocuments().some(d => d.type === OTHER_DOCUMENT_TYPES.SELFIE);
  }

  hasVerigramDocuments() {
    const types: DOCUMENT_TYPES[] = Object.values(VERIGRAM_DOCUMENT_TYPES);

    return uniq(this.getDocumentsByTypes(types).map(d => d.type)).length === types.length;
  }

  getDocumentsByTypes(types: DOCUMENT_TYPES[]): Document[] {
    return documents.getDocuments.filter(d => types.includes(d.type));
  }

  createDownloadLink(downloadedDocument: Blob, headers: ResponseHeaders) {
    const link = URL.createObjectURL(downloadedDocument);
    const a = document.createElement('a');
    a.href = link;
    a.download = this.getFileNameFromHeaders(headers) ?? '';
    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(link);
    document.body.removeChild(a);
  }

  private getFileNameFromHeaders(headers: ResponseHeaders): string | null {
    const contentDisposition = headers[HEADERS.CONTENT_DISPOSITION];
    if (contentDisposition.includes(CONTENT_FILENAME.ENCODED)) {
      const encodedFileName = contentDisposition.split(`${CONTENT_FILENAME.ENCODED}=`)[1].replace("utf-8''", '');
      const contentType = headers[HEADERS.CONTENT_TYPE];
      const extension = getExtension(trim(contentType.replace('charset=UTF-8', ''), ' ;') as MIME);
      const fileName = trim(decodeURI(encodedFileName), ' .');

      return `${fileName}.${extension}`;
    }

    if (contentDisposition.includes(CONTENT_FILENAME.DEFAULT)) {
      return contentDisposition.split(`${CONTENT_FILENAME.DEFAULT}=`)[1];
    }

    return null;
  }
}

export const DocumentService = new DocumentServiceClass();
