import { asyncMiddleware } from '@/router';
import { calculator } from '@/store/modules/calculator';

export const calculatorMiddleware: asyncMiddleware = async () => {
  const loaded = await calculator.initProducts();

  if (!loaded) {
    return; // Calculator is required everywhere if it is not loaded stay where we are
  }
};
