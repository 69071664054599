
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { FieldRules, FieldValue, LmsFieldState } from '@/components/registration/models/field';

@Component
export default class LmsField<TValue = FieldValue> extends Vue implements LmsFieldState<TValue> {
  @Prop({ required: true })
  name!: string;
  @Prop({ required: false, default: () => ({}) })
  rules!: FieldRules<TValue>;
  @Prop({ default: '' })
  initial!: TValue;

  value: TValue = this.initial;

  @Prop({ default: '' })
  errorMessage = '';

  error = '';

  focused = false;

  get isValid(): boolean {
    return !this.error;
  }

  @Watch('initial')
  syncValue() {
    this.$nextTick(() => {
      this.value = this.initial;
    });
  }

  validate(): void {
    Object.keys(this.rules).forEach(rule => {
      const check = this.rules[rule](this.value);

      if (check && !this.error) {
        this.error = check;
      }
    });
  }

  invalidate(): void {
    this.error = '';
  }

  @Watch('errorMessage')
  updateError() {
    this.error = this.errorMessage;
  }

  @Emit('input')
  handleInput() {
    return this.value;
  }

  @Emit('change')
  handleChange() {
    return this.value;
  }
}
