import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { saveIovation } from '@/api/risks-report';

export interface IovationState {
  hash: string;
}

@Module({ name: 'iovation', store, dynamic: true })
export default class Iovation extends VuexModule implements IovationState {
  hash = '';

  @Action
  async init() {
    const { value: iovationHash } = document.getElementById('ioBB') as HTMLInputElement;

    try {
      await saveIovation({ hash: iovationHash || this.getHash });
    } catch (e) {
      console.error(e);
    }

    this.set('');
  }

  get getHash() {
    return this.hash;
  }

  @Mutation
  set(hash: string) {
    this.hash = hash;
  }

  @Action
  reset() {
    this.set('');
  }
}

export const iovation = getModule(Iovation);
