
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'transition-sharp'
})
export default class TransitionSharpComponent extends Vue {
  @Prop({ default: {} }) top: any;
  @Prop({ default: {} }) bottom: any;
}
