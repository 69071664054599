import request, { HTTP_METHODS } from '@/utils/request';
import { AxiosPromise } from 'axios';
import { Agreement, AgreementRequestList, AgreementSchedule } from '@/api/models/agreements';

export const getAgreementList = (params?: AgreementRequestList): AxiosPromise<Agreement[]> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/agreement/agreements',
    method: HTTP_METHODS.GET,
    params
  });

export const getAgreementById = (agreementId: string): AxiosPromise<Agreement> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: `/agreement/agreements/${agreementId}?sections[documents]=1`,
    method: HTTP_METHODS.GET
  });

export const getAgreementSchedule = (scheduleId: string): AxiosPromise<AgreementSchedule> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: `/agreement/schedules/${scheduleId}`,
    method: HTTP_METHODS.GET
  });
