export enum MIME {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf',
  SIGNATURE = 'application/octet-stream',
  HTML = 'text/html'
}

export const enum FileType {
  IMAGE = 'image'
}

export const enum FileMaxSize {
  IMAGE = 16,
  BANK_STATEMENT = 20
}

export function getExtension(mime: MIME) {
  switch (mime) {
    case MIME.JPEG:
    case MIME.JPG:
      return 'jpg';
    case MIME.PNG:
      return 'png';
    case MIME.SIGNATURE:
      return 'sig';
    case MIME.HTML:
      return 'html';
    case MIME.PDF:
    default:
      return 'pdf';
  }
}

export function validateFileType(file: File, type: FileType) {
  const filetype = file.type as MIME;
  switch (type) {
    case FileType.IMAGE:
      return [MIME.JPEG, MIME.JPG, MIME.PNG].includes(filetype);
    default:
      return false;
  }
}

export function validateFileSize(file: File, size: FileMaxSize) {
  return file.size <= size * 1000 * 1000;
}
