var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"transition-container"},[_c('div',{staticClass:"transition-top",style:({
      height: (_vm.top.height || 40) + 'px',
      backgroundColor: _vm.top.bg || '#ffffff'
    })}),_c('div',{staticClass:"transition-bottom",style:({
      height: (_vm.bottom.height || 40) + 'px',
      backgroundColor: _vm.bottom.bg || '#ffffff'
    })})])
}
var staticRenderFns = []

export { render, staticRenderFns }