
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import Field from '@/components/registration/Field.vue';
import { InputSetting } from '@/components/registration/models/input';
import { APPLICATION_ROUTES } from '@/router/routes_type';
import { FieldValue } from '@/components/registration/models/field';

const defaultSetting: InputSetting = {
  mask: '',
  placeholder: false,
  title: false,
  type: 'text',
  tooltip: false,
  pattern: value => value,
  maskPattern: value => value
};

class FieldInputClass extends Field<FieldValue> {}

@Component({
  name: 'LmsInput'
})
export default class FieldInput extends Mixins(FieldInputClass) {
  @Prop({ default: () => defaultSetting })
  setting!: InputSetting;
  @Prop({ default: () => false })
  autoFocus: boolean;
  @Prop({ default: () => '' })
  helpLink: string;

  masked = this.initial;
  loginPage = { name: APPLICATION_ROUTES.LOGIN_PAGE };

  mounted() {
    if (this.autoFocus) {
      (this.$refs[this.refName] as HTMLElement)?.focus();
    }
  }

  get refName(): string {
    return `${this.name}-field`;
  }

  get placeholder() {
    return this.$t(`FORM.FIELDS.${this.name}.${this.setting.placeholder ? 'PLACEHOLDER' : 'TITLE'}`);
  }

  get tooltip() {
    return this.setting.tooltip ? this.$t(`FORM.FIELDS.${this.name}.TOOLTIP`) : '';
  }

  @Emit('change')
  onChange() {
    return this.value;
  }

  @Emit('focus')
  onFocus() {
    this.focused = true;
    this.invalidate();
  }

  @Emit('blur')
  onBlur() {
    this.focused = false;
    this.validate();
    return this.value;
  }

  @Emit('input')
  onInput() {
    this.applyMask();

    if (this.setting.pattern) {
      this.value = this.setting.pattern(this.masked);
    } else {
      this.value = this.masked;
    }

    return this.value;
  }

  private applyMask(): void {
    if (this.setting.maskPattern) {
      this.masked = this.setting.maskPattern(this.masked);
    }
  }
}
