
import { Vue, Component } from 'vue-property-decorator';
import { PROFILE_ROUTES } from '@/router/routes_type';

@Component({
  name: 'ProfileNavBarSettings'
})
export default class ProfileNavBarSettings extends Vue {
  linkRouteName = PROFILE_ROUTES.EDIT_PAGE;
}
