import { LOCALES } from '@/api/models/common';

export type SectionContent<TValue extends any = unknown> = Record<string, TValue>;

export const enum SECTIONS {
  REGION = 'region',
  MARITAL_STATUS = 'marital_status_list',
  CHILDREN_COUNT = 'children_count_list',
  EMPLOYMENT_STATUS = 'employment_status_list',
  EMPLOYMENT_POSITION = 'employment_position_list',
  INDUSTRY = 'industry_list_with_income',
  LOAN_PURPOSE = 'loan_purpose',
  IDENTITY_DOCUMENT_ISSUED_BY = 'identity_document_issued_by',
  AGREEMENT_SIGNING_TYPE = 'agreement_signing_type',
  FAQ = 'faq',
  FINANCES_FAQ = 'finances_faq'
}

type Section = Partial<Record<SECTIONS, SectionContent>>;

export type Content = Partial<Record<LOCALES, Section>>;

export interface ContentState {
  content: Content;
}
