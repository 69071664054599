
import { Prop, Component, Mixins } from 'vue-property-decorator';
import Field from '@/components/registration/Field.vue';
import { VNode } from 'vue';
import { noCurrencyMoney } from '@/core/plugins/filters';
import { SettingAmount } from '@/store/modules/calculator';

interface MarksStyle {
  transform: string;
  fontWeight: string;
  opacity: string;
  whiteSpace: string;
}

interface MarksOptions {
  style: MarksStyle;
  label: VNode;
}

interface Marks {
  [key: string]: MarksOptions;
}

interface SliderState {
  label: string;
  settings: SettingAmount;
  marks: Marks;
}

@Component
export default class CalculatorSlider extends Mixins(Field) implements SliderState {
  @Prop({ required: true })
  settings!: SettingAmount;
  @Prop({ default: 'Label Text' })
  label!: '';
  @Prop({ default: false })
  disabled!: false;

  value = Number(this.initial);

  get marks() {
    return {
      [this.settings.min]: {
        style: {
          transform: 'none',
          fontWeight: 'normal',
          opacity: '1',
          whiteSpace: 'nowrap'
        },
        label: this.$createElement('span', noCurrencyMoney(this.settings.min.toString()))
      },
      [this.settings.max]: {
        style: {
          transform: 'translateX(-100%)',
          fontWeight: 'normal',
          opacity: '1',
          whiteSpace: 'nowrap'
        },
        label: this.$createElement('span', noCurrencyMoney(this.settings.max.toString()))
      }
    };
  }
}
