
import { Component, Vue } from 'vue-property-decorator';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import Card from '@/components/Card.vue';

import 'swiper/css/swiper.css';
import { CardData } from '@/components/layout/sections/types';

@Component({
  name: 'SectionAnyGoals',
  components: {
    Card,
    Swiper,
    SwiperSlide
  }
})
export default class SectionAnyGoalsComponent extends Vue {
  private swiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 10
  };

  get cards(): CardData[] {
    return [
      {
        icon: require('@/assets/img/icons/card1.svg'),
        iconHover: require('@/assets/img/icons/card1hover.svg'),
        text: this.$t('LAYOUT_SECTIONS.SECTION_ANY_GOALS.CARD_1').toString()
      },
      {
        link: { name: 'CreditForBusinessPage' },
        icon: require('@/assets/img/icons/card2.svg'),
        iconHover: require('@/assets/img/icons/card2hover.svg'),
        text: this.$t('LAYOUT_SECTIONS.SECTION_ANY_GOALS.CARD_2').toString()
      },
      {
        icon: require('@/assets/img/icons/card3.svg'),
        iconHover: require('@/assets/img/icons/card3hover.svg'),
        text: this.$t('LAYOUT_SECTIONS.SECTION_ANY_GOALS.CARD_3').toString()
      },
      {
        icon: require('@/assets/img/icons/card4.svg'),
        iconHover: require('@/assets/img/icons/card4hover.svg'),
        text: this.$t('LAYOUT_SECTIONS.SECTION_ANY_GOALS.CARD_4').toString()
      },
      {
        icon: require('@/assets/img/icons/card5.svg'),
        iconHover: require('@/assets/img/icons/card5hover.svg'),
        text: this.$t('LAYOUT_SECTIONS.SECTION_ANY_GOALS.CARD_5').toString()
      }
    ];
  }
}
