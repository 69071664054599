
import { Vue, Component } from 'vue-property-decorator';

@Component({ name: 'ReportsPage' })
export default class ReportsPage extends Vue {
  get documents() {
    return [
      {
        title: this.$t('REPORTS.FINANCIAL_REPORT_2021').toString(),
        link: '#'
      }
    ];
  }
}
