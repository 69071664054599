import { LOCALES } from '@/api/models/common';
import dayjs from 'dayjs';
import { DATE_TIME_PATTERN_TO_SHOW } from '@/core/config/setup/product';

export enum VERIFICATION_CHANNEL {
  COID = 'coid',
  ONE_CLICK = 'one_click',
  VERIGRAM = 'verigram',
  SMS = 'sms',
  MAILER = 'mailer',
  FANOUT = 'fanout'
}

export const enum VERIFICATION_STATE {
  NEW = 'new',
  REPEATED = 'repeated',
  CONFIRMED = 'confirmed',
  EXPIRED = 'expired',
  ERROR = 'error'
}

export const enum VERIFICATION_TYPE {
  MOBILE_CONFIRMATION = 'mobile_confirmation',
  EMAIL_CONFIRMATION = 'email_confirmation',
  PASSWORD_RECOVERY = 'password_recovery',
  AGREEMENT_CONFIRMATION = 'agreement_confirmation',
  BANK_CARD_CONFIRMATION = 'bank_card_confirmation',
  IDENTITY_CONFIRMATION = 'identity_confirmation',
  LIVELINESS_CONFIRMATION = 'liveliness_confirmation'
}

type TVerificationAttributes = {
  language?: LOCALES;
  mobile?: boolean;
  redirectPath?: string;
  email?: string;
  companyId?: string;
  locale?: string;
  mediaId?: string;
};

type TVerificationDetails = {
  bestFrameMediaId?: string;
};

export type VerificationSubject = {
  identity: string; // unique number
  type: VERIFICATION_TYPE;
  attributes?: TVerificationAttributes;
};

export interface StartVerificationPayload<TSubject extends VerificationSubject = VerificationSubject> {
  subject: TSubject;
  channel?: VERIFICATION_CHANNEL;
}

export interface ConfirmVerificationPayload {
  code: string;
}

export interface VerifyVerificationPayload {
  attributes: Record<string, string | number>;
}

export interface VerificationResponse {
  id: string;
  recipientId: string;
  state: VERIFICATION_STATE;
  subject: VerificationSubject;
  details: TVerificationDetails;
  channel: string | null;
  throttleReset: number;
}

export const enum VERIFICATION_CREATE_STATUS {
  INVALID_IDENTIFIER = 400,
  VERIFICATION_ALREADY_EXIST = 409,
  VERIFICATION_IDENTITY_NOT_SUPPORTED = 412
}

export const enum VERIFICATION_CONFIRM_STATUS {
  VERIFICATION_CONFIRMED = 204,
  INVALID_REQUEST_OR_CONFIRMATION_CODE = 400,
  VERIFICATION_NOT_FOUND = 404,
  INVALID_VERIFICATION_CODE = 409,
  IS_ALREADY_CONFIRMED_OR_EXPIRED = 410,
  MAXIMUM_NUMBER_OF_ATTEMPTS_REACHED = 429
}

export const enum VERIFICATION_REPEAT_STATUS {
  VERIFICATION_REPEATED = 204,
  INVALID_REQUEST = 400,
  VERIFICATION_NOT_FOUND = 404,
  INVALID_VERIFICATION_SUBMISSION = 409,
  IS_ALREADY_CONFIRMED_OR_EXPIRED = 410,
  TOO_MANY_ATTEMPTS_OR_MAXIMUM_NUMBER_OF_REPEATS_REACHED = 429
}

export const enum VERIFICATION_REPEAT_HEADERS {
  RETRY_AFTER = 'retry-after'
}

export const nextTryDateTime = (headers: Record<VERIFICATION_REPEAT_HEADERS, string>): string => {
  const retryAfter = 'retry-after';
  const unix = Number(headers[retryAfter]);
  const date = dayjs(unix * 1000);
  return date.format(DATE_TIME_PATTERN_TO_SHOW);
};
