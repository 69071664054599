
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { getNewsArticle } from '@/views/News/setting';
import { Article } from '@/components/NewsArticle/types';
import SectionQuestionnaire from '@/components/layout/sections/SectionQuestionnaire.vue';
import BackButton from '@/components/BackButton/index.vue';
import { LOCALES } from '@/api/models/common';

@Component({
  name: 'NewsInfoPage',
  components: { BackButton, SectionQuestionnaire }
})
export default class NewsInfoPage extends Vue {
  @Prop({ required: true }) articleId!: string;

  articleLoaded = false;

  article: Article | null = null;

  mounted() {
    this.loadArticle();
  }

  @Watch('$i18n.locale')
  async loadArticle() {
    this.article = await getNewsArticle(this.$i18n.locale as LOCALES, this.articleId);
    this.articleLoaded = true;
  }
}
