import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { DocumentsState } from '@/store/modules/models/documents';
import {
  Document,
  DOCUMENT_TYPES,
  ID_DOCUMENT_TYPES,
  OTHER_DOCUMENT_TYPES,
  VERIGRAM_DOCUMENT_TYPES
} from '@/api/models/document';
import { getDocumentsList } from '@/api/document';

export const PERSONAL_DOCUMENTS: DOCUMENT_TYPES[] = [
  ID_DOCUMENT_TYPES.FRONT,
  ID_DOCUMENT_TYPES.BACK,
  OTHER_DOCUMENT_TYPES.SELFIE,
  OTHER_DOCUMENT_TYPES.BANK_STATEMENT,
  VERIGRAM_DOCUMENT_TYPES.ALL,
  VERIGRAM_DOCUMENT_TYPES.BEST_FRAME
];

@Module({ name: 'documents', store, dynamic: true })
export class Documents extends VuexModule implements DocumentsState {
  documents: Document[] = [];

  get getDocuments(): Document[] {
    return this.documents;
  }

  @Mutation
  setDocuments(documents: Document[]) {
    this.documents = documents;
  }

  @Action
  async initDocuments() {
    try {
      const { data: documents } = await getDocumentsList({ types: PERSONAL_DOCUMENTS });

      this.setDocuments(documents);

      return true;
    } catch (e) {
      return false;
    }
  }

  @Action
  reset() {
    this.setDocuments([]);
  }
}

export const documents = getModule(Documents);
