import axios from 'axios';
import { client } from '@/store/modules/client';

export enum HTTP_METHODS {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export const enum HEADERS {
  CONTENT_TYPE = 'content-type',
  CONTENT_DISPOSITION = 'content-disposition'
}

export type ResponseHeaders = Record<HEADERS, string> & {
  [HEADERS.CONTENT_TYPE]: string;
};

export const enum CONTENT_FILENAME {
  DEFAULT = 'filename',
  ENCODED = 'filename*'
}

export interface ActionFailedViolationParameter {
  [key: string]: string;
}

export interface ActionFailedViolation {
  parameters: ActionFailedViolationParameter;
  propertyPath: string;
  title: string;
  type?: string;
}

const request = axios.create({
  timeout: 30000
});

function refreshTokenFabric() {
  let activeRequest: Promise<boolean>;
  let processing = false;

  return function() {
    if (processing) {
      return activeRequest;
    }

    processing = true;
    activeRequest = client.initRefreshTokens().finally(() => {
      processing = false;
    });

    return activeRequest;
  };
}

const refreshToken = refreshTokenFabric();

request.interceptors.request.use(
  config => {
    if (client.accessToken) {
      config.headers['Authorization'] = `Bearer ${client.accessToken}`;
    }
    config.params = config.params || {};
    return config;
  },
  error => Promise.reject(error)
);

request.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (!error.response) {
      return Promise.reject(error);
    }

    if (error.response.status !== 401) {
      return Promise.reject(error.response);
    }

    if (!client.refreshToken || (await client.tokenExpired(client.refreshToken))) {
      client.logOut();
      return Promise.reject(error.response);
    }

    if (client.refreshToken) {
      try {
        const tokensRefreshed = await refreshToken();

        if (!tokensRefreshed) {
          client.logOut();
          return Promise.reject(new Error());
        }

        return new Promise((resolve, reject) => {
          request
            .request(error.config)
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        });
      } catch (e) {
        return Promise.reject(e);
      }
    }
  }
);

export default request;
