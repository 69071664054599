import request, { HTTP_METHODS } from '@/utils/request';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import {
  ConfirmVerificationPayload,
  StartVerificationPayload,
  VerificationResponse,
  VerifyVerificationPayload
} from './models/verification';
import { client } from '@/store/modules/client';

const verificationRequest = (config: AxiosRequestConfig) => {
  const baseURL = client.accessToken ? process.env.VUE_APP_BASE_API_AUTH : process.env.VUE_APP_BASE_API_ANON;

  const verificationConfig: AxiosRequestConfig = {
    ...config,
    baseURL
  };

  return request(verificationConfig);
};

export const startVerification = <TResponse extends VerificationResponse = VerificationResponse>(
  data: StartVerificationPayload
): AxiosPromise<TResponse> => {
  return verificationRequest({
    baseURL: process.env.VUE_APP_BASE_API_ANON,
    url: '/verification/verifications',
    method: HTTP_METHODS.POST,
    data
  });
};

export const repeatVerification = (verificationId: string) =>
  verificationRequest({
    baseURL: process.env.VUE_APP_BASE_API_ANON,
    url: `/verification/verifications/${verificationId}/repeat`,
    method: HTTP_METHODS.PUT
  });

export const verifyVerification = (verificationId: string, data: VerifyVerificationPayload) =>
  verificationRequest({
    baseURL: process.env.VUE_APP_BASE_API_ANON,
    url: `/verification/verifications/${verificationId}/verify`,
    method: HTTP_METHODS.PUT,
    data
  });

export const confirmVerification = (verificationId: string, data: ConfirmVerificationPayload) =>
  verificationRequest({
    baseURL: process.env.VUE_APP_BASE_API_ANON,
    url: `/verification/verifications/${verificationId}/confirm`,
    method: HTTP_METHODS.PUT,
    data
  });

export const getVerification = <TResponse extends VerificationResponse = VerificationResponse>(
  verificationId: string
): AxiosPromise<TResponse> => {
  return verificationRequest({
    baseURL: process.env.VUE_APP_BASE_API_ANON,
    url: `/verification/verifications/${verificationId}`,
    method: HTTP_METHODS.GET
  });
};
