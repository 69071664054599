import Vue, { ComponentOptions } from 'vue';
import App from './App.vue';
// Till hard launch
// import "./registerServiceWorker"
import router from './router';
import store from './store';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/global-defaults.scss';
import './assets/css/theme-override.scss';
import ElementUI from 'element-ui';
import i18n from '@/core/plugins/vue-i18n';
import VueRellax from 'vue-rellax';
import VueMask from 'v-mask';
import '@/core/plugins/filters';
import VueMeta from 'vue-meta';

Vue.config.productionTip = false;
Vue.use(ElementUI, {
  i18n: (key: string, value: string) => i18n.t(key, value)
});
Vue.use(VueMask, {
  placeholders: {
    B: /[0-9A-Z]/,
    D: /[0-9a-zA-Zа-яА-Я]/
  }
});
Vue.use(VueRellax);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

const vueConfig: ComponentOptions<Vue> = {
  router,
  store,
  i18n,
  render: h => h(App)
};
new Vue(vueConfig).$mount('#app');
