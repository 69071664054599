import request, { HTTP_METHODS } from '@/utils/request';
import { RiskReportIovationPayload, RiskReportSeonPayload } from '@/api/models/risks-report';

export const saveIovation = (data: RiskReportIovationPayload) =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/risks-report/webhook/iovation',
    method: HTTP_METHODS.POST,
    data
  });

export const saveSeon = (data: RiskReportSeonPayload) =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/risks-report/webhook/seon',
    method: HTTP_METHODS.POST,
    data
  });
