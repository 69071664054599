import request, { HTTP_METHODS } from '@/utils/request';
import { BalanceTotals, DpdEntry, InvoiceBalancesQuery } from '@/api/models/lending';
import { AxiosPromise } from 'axios';

const invoicesPath = `${process.env.VUE_APP_BASE_API_AUTH}/lending/invoices`;

export const invoiceTotals = (query: InvoiceBalancesQuery) =>
  request.get<BalanceTotals>(`${invoicesPath}/totals`, { params: query });

export const getLoanDpdEntry = (loanId: string): AxiosPromise<DpdEntry> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: `/lending/dpd-entries/latest?loanId=${loanId}`,
    method: HTTP_METHODS.GET
  });
