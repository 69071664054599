
import { Component, Vue, Prop } from 'vue-property-decorator';
import { TransitionRoundProp } from '@/components/layout/transitions/TransitionRound';

@Component({
  name: 'transition-sharp'
})
export default class TransitionSharpComponent extends Vue {
  @Prop({ default: {} }) top!: TransitionRoundProp;
  @Prop({ default: {} }) bottom!: TransitionRoundProp;

  get bottomHeight() {
    return this.bottom.height;
  }
}
