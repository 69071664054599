import { NavigationGuard } from 'vue-router';
import { loader } from '@/utils/loader';
import { asyncMiddleware, middlewareResult } from '@/router';

export const middlewares: NavigationGuard = async (to, from, next) => {
  if (to.meta?.middleware) {
    await loader.show();

    let redirect: middlewareResult;

    for (const middleware of to.meta.middleware as asyncMiddleware[]) {
      if (!redirect) {
        redirect = await middleware({ to, from });
        if (redirect) {
          break;
        }
      }
    }

    loader.close();
    next(redirect);
  } else {
    next();
  }
};
