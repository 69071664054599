import { asyncMiddleware } from '@/router';
import { getRoute } from '@/router/router';
import { SKIP_ROUTE_CHECK } from '@/router/routes';
import { MULTI_LOAN_ROUTES, REGISTRATION_ROUTES, ROUTE } from '@/router/routes_type';

const NOT_REDIRECTABLE_PAGES: ROUTE[] = [REGISTRATION_ROUTES.APPLICATION_REJECT_PAGE];

export const checkRouteMiddleware: asyncMiddleware = async context => {
  if (context.to.params.SKIP_ROUTE_CHECK && context.to.params.SKIP_ROUTE_CHECK === SKIP_ROUTE_CHECK) {
    return;
  }

  let route = await getRoute();

  if (NOT_REDIRECTABLE_PAGES.includes(route)) {
    route = MULTI_LOAN_ROUTES.DISBURSEMENT_TYPE_PAGE;
  }
  if (context.to.name !== route) {
    return { name: route, params: context.to.params };
  }
};
