import { iovation } from '@/store/modules/iovation';
import { progress } from '@/store/modules/progress';
import { client } from '@/store/modules/client';
import { BankCardVerificationService } from '@/services/bankCard';
import { agreements } from '@/store/modules/agreements';
import { applications } from '@/store/modules/application';
import { cleanUpMarketingMetrics } from '@/utils/marketing';
import { clearRoute } from '@/router/router';
import { debtSettlementApplication } from '@/store/modules/debtSettlementApplication';

export function resetState(): void {
  clearRoute();
  client.cleanClient();
  progress.reset();
  iovation.reset();
  applications.reset();
  agreements.reset();
  BankCardVerificationService.removeVerification();
  debtSettlementApplication.reset();
  cleanUpMarketingMetrics();
}
