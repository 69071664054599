
import { Component, Prop, Vue } from 'vue-property-decorator';
import dot from 'dot-object';
import { FieldData, LmsFieldState } from '@/components/registration/models/field';
import { LmsFormState } from '@/components/registration/models/form';
import { ActionFailedViolation, ActionFailedViolationParameter } from '@/utils/request';
import { AutocompleteType } from '@/components/registration/models/input';
import { FORM_ERRORS } from '@/components/registration/settings/rules';

const ERROR_NOTIFICATION_DURATION = 10000;

@Component({
  name: 'LmsForm'
})
export default class Form extends Vue implements LmsFormState {
  @Prop({ default: 'off' })
  autocomplete!: AutocompleteType;

  validateFields() {
    this.fields().forEach(field => field.validate());
  }

  fields() {
    return (this.$children as LmsFieldState[]).filter(child => child.name);
  }

  fieldsData() {
    return this.fields().reduce((formData, current) => {
      if (current.value === '') {
        return formData;
      }

      formData[current.name] = current.value;
      return formData;
    }, {} as FieldData);
  }

  fieldsValid() {
    return !this.fields().filter(field => !field.isValid).length;
  }

  get errorTitle() {
    return this.$t('FORM.ERROR_NOTIFICATION_TITLE').toString() || 'Error!';
  }

  setErrors(violations?: ActionFailedViolation[], details?: string) {
    if (!violations) {
      return this.$notify.error({
        title: this.errorTitle,
        message: details || this.$t(`FORM.${FORM_ERRORS.FIELD_ERROR_DEFAULT}`).toString(),
        duration: ERROR_NOTIFICATION_DURATION
      });
    }

    const clientRestrictionViolations = violations.filter(
      violation => violation.type && violation.type.startsWith('urn:uuid:client.restrictions')
    );

    if (clientRestrictionViolations.length > 0) {
      clientRestrictionViolations.forEach(violation =>
        this.$notify.error({
          title: this.errorTitle,
          message: this.$t(this.toFieldError(violation.type), this.parseParameters(violation.parameters)).toString(),
          duration: ERROR_NOTIFICATION_DURATION
        })
      );

      return;
    }

    // Exclude BE violation with schema definition
    const excludeParameter = '{{ schema }}';
    const fieldErrors = violations.filter(violation => !violation.parameters[excludeParameter]);

    fieldErrors.forEach(error => {
      this.fields().forEach(field => {
        if (field.name.search(error.propertyPath) !== -1) {
          field.error = error.title.replace(/\./g, '').split(':')[0];
        }
      });
    });
  }

  clearErrors() {
    this.fields().forEach(field => {
      field.error = '';
    });
  }

  parseParameters(parameters: ActionFailedViolationParameter): ActionFailedViolationParameter {
    return Object.fromEntries(
      Object.entries(parameters).map(([k, v]) => [k.replaceAll(/[{}]/gi, '').trim(), v])
    ) as ActionFailedViolationParameter;
  }

  toFieldError(type?: string): string {
    const defaultError = `FORM.${FORM_ERRORS.FIELD_ERROR_DEFAULT}`;
    if (!type) {
      return defaultError;
    }
    const fieldErrorType = `FIELD_ERROR_${type.substring(type.lastIndexOf('.') + 1).toUpperCase()}`;
    const formError = FORM_ERRORS[fieldErrorType as keyof typeof FORM_ERRORS];

    return formError ? `FORM.${formError}` : defaultError;
  }

  onSubmit(): void {
    this.clearErrors();

    this.validateFields();
    if (this.fieldsValid()) this.$emit('submit', dot.object(this.fieldsData()));
  }
}
