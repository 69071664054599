import { ElLoadingComponent } from 'element-ui/types/loading';
import { Loading } from 'element-ui';

interface Loader {
  loading: ElLoadingComponent | null;
  show: () => Promise<void>;
  close: () => void;
}

export const loader: Loader = {
  loading: null,
  async show() {
    if (this.loading) {
      return;
    }
    this.loading = Loading.service({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
  },
  close: () => {
    if (loader.loading) {
      loader.loading.close();
      loader.loading = null;
    }
  }
};
