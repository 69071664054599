import { ClientCreatePayload, DisbursementsResponseMap, Profile } from '@/api/models/client';
import { Login } from '@/api/models/login';
import { Payload } from '@/store';

export enum AUTO_LOGIN_TOKENS {
  X_ACCESS_TOKEN = 'x-access-token',
  X_REFRESH_TOKEN = 'x-refresh-token'
}

export interface ClientState {
  accessToken: string;
  refreshToken: string;
  profile?: Profile;
  authorized: boolean;
  initProfile: (context?: Payload) => Promise<boolean>;
  initLogin: (clientInfo: Login) => Promise<void>;
  initCreateClient: (payload: ClientCreatePayload) => Promise<void>;
  initRefreshTokens: () => Promise<boolean>;

  disbursements: DisbursementsResponseMap;
  setClientDisbursements: (disbursements: DisbursementsResponseMap) => void;
  initProfileDisbursements: (context: Payload) => Promise<boolean>;
}
