import { getVerification } from '@/api/verification';
import { Verification } from '@/services/classes/verification';
import { VERIFICATION_CHANNEL, VerificationResponse, VerificationSubject } from '@/api/models/verification';
import Vue from 'vue';

export class VerificationControllerClass<TResponse extends VerificationResponse = VerificationResponse>
implements VerificationControllerClass<TResponse> {
  state: {
    value: Verification<TResponse> | null;
  };

  constructor() {
    this.state = new Vue({
      data: {
        value: null
      }
    });
  }

  get verificationStore() {
    return this.state.value;
  }
  set verificationStore(value) {
    Vue.set(this.state, 'value', value);
  }

  private craftVerificationState(subject: VerificationSubject, channel?: VERIFICATION_CHANNEL) {
    return new Verification<TResponse>(subject, channel);
  }

  async createVerification(subject: VerificationSubject, channel?: VERIFICATION_CHANNEL) {
    this.verificationStore = this.craftVerificationState(subject, channel);
    await this.verificationStore.registerVerification();
    if (this.verificationStore.isConfirmed || this.verificationStore.isExpired) {
      await this.verificationStore.newVerification();
    }

    return this.verificationStore;
  }

  async restoreVerification(
    verificationId: string,
    channel?: VERIFICATION_CHANNEL
  ): Promise<Verification<TResponse> | null> {
    const {
      data: verification,
      data: {
        subject: { identity, type }
      }
    } = await getVerification<TResponse>(verificationId);

    if (verification.channel !== channel) {
      return null;
    }

    if (!this.verificationStore) {
      this.verificationStore = this.craftVerificationState({ identity, type }, channel);
    }
    this.verificationStore.setVerification(verification);

    return this.verificationStore;
  }

  async reloadVerification() {
    return this.verificationStore?.reloadVerification();
  }

  get verification() {
    return this.verificationStore;
  }

  destroyVerification() {
    this.verificationStore = null;
  }
}
