import { client } from '@/store/modules/client';
import { asyncMiddleware } from '@/router';
import { TOKENS, Tokens } from '@/api/models/client';
import { getRoute } from '@/router/router';
import { resetState } from '@/store/state';

export const impersonate: asyncMiddleware = async context => {
  if (context.to.query.impersonate) {
    resetState();
    const tokens = { [TOKENS.ACCESS_TOKEN]: context.to.query.impersonate };
    client.setClientTokens(tokens as Tokens);

    return { name: await getRoute() };
  }
};
