import { client } from '@/store/modules/client';
import { asyncMiddleware } from '@/router';
import { APPLICATION_ROUTES } from '@/router/routes_type';
const redirectName = { name: APPLICATION_ROUTES.LOGIN_PAGE };

export const auth: asyncMiddleware = async () => {
  if (!(await client.initProfile())) {
    return redirectName;
  }
};
