
import { Component, Vue } from 'vue-property-decorator';
import { APPLICATION_ROUTES } from '@/router/routes_type';
import LmsCalculatorLight from '@/components/calculator/CalculatorLight.vue';

@Component({
  name: 'CardCalculator',
  components: { LmsCalculatorLight }
})
export default class CardCalculator extends Vue {
  loginPage = { name: APPLICATION_ROUTES.LOGIN_PAGE };
}
