import request, { HTTP_METHODS } from '@/utils/request';
import { AxiosPromise } from 'axios';
import { SectionContent } from '@/store/modules/models/content';

export enum CONTENT_LOCALES {
  ru = 'ru_KZ',
  kk = 'kk_KZ'
}

export const getContentSectionKeys = (locale: CONTENT_LOCALES, section: string): AxiosPromise<SectionContent> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_ANON,
    url: `/content/${locale}/${section}/keys`,
    method: HTTP_METHODS.GET
  });
