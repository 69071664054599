import { VerificationControllerClass } from '@/services/classes/verificationControllerClass';
import { VERIFICATION_CHANNEL, VERIFICATION_TYPE } from '@/api/models/verification';
import { recoveryPasswordReset } from '@/api/client';

class PasswordRecoveryVerificationClass extends VerificationControllerClass {
  data = { identifier: '', channel: VERIFICATION_CHANNEL.FANOUT };

  get identifierIsEmail() {
    return this.data.identifier.includes('@');
  }

  get identifierIsPhone() {
    return this.data.identifier.includes('+7');
  }

  initVerification() {
    return this.createVerification(
      {
        identity: this.data.identifier,
        type: VERIFICATION_TYPE.PASSWORD_RECOVERY
      },
      this.data.channel
    );
  }

  async passwordReset(newPassword: string) {
    if (!this.verificationStore?.isConfirmed) {
      return;
    }
    const params = {
      verificationId: this.verificationStore.verificationId,
      ...this.verificationStore.payload
    };
    await recoveryPasswordReset(newPassword, params);
    this.verificationStore.removeVerification();
  }
}
export const PasswordRecoveryService = new PasswordRecoveryVerificationClass();
