import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { Product } from '@/api/models/product';
import { getProduct } from '@/api/product';
import { getCalculatorAmount, getCalculatorTerm, setCalculatorAmount, setCalculatorTerm } from '@/utils/cookies';
import { PRODUCT } from '@/core/config/setup/product';

export interface SettingAmount {
  min: number;
  max: number;
  step: number;
}

interface Setting {
  amount: SettingAmount;
  term: number[];
}

const DEFAULT_MIN_CALCULATOR_VALUE = 500000; // Amount is a business requirement
const DEFAULT_TERM_VALUE = 6;

@Module({ name: 'calculator', store, dynamic: true })
class Calculator extends VuexModule {
  amount = getCalculatorAmount() ?? DEFAULT_MIN_CALCULATOR_VALUE;
  term = getCalculatorTerm();
  private products: Product[] = [];
  selectedProductType = PRODUCT.INSURANCE;

  get configuration() {
    return this.selectedProduct?.matrix.ranges ?? [];
  }

  get selectedProduct() {
    return this.products.find(p => p.revision.alias === this.selectedProductType);
  }

  get sliderSetting(): Setting {
    if (!this.configuration.length || !this.amount) {
      return {
        amount: {
          min: DEFAULT_MIN_CALCULATOR_VALUE,
          max: DEFAULT_MIN_CALCULATOR_VALUE,
          step: 1
        },
        term: [DEFAULT_TERM_VALUE]
      };
    }

    const selectedAmount = this.amount;
    return this.configuration.reduce(
      ({ amount, term }, cur) => {
        const curMin = Number(cur.amount.min);
        const curMax = Number(cur.amount.max);
        const curStep = Number(cur.amount.step);

        const rangeMatch = selectedAmount >= curMin && selectedAmount <= curMax;

        return {
          amount: {
            min: Math.min(amount.min, curMin),
            max: Math.max(amount.max, curMax),
            step: rangeMatch ? curStep : amount.step
          },
          term: rangeMatch ? cur.terms : term
        };
      },
      {
        amount: {
          min: Number.MAX_SAFE_INTEGER,
          max: 0,
          step: 0
        },
        term: [] as number[]
      } as Setting
    );
  }

  @Mutation
  setAmount(amount: number) {
    this.amount = amount;
    setCalculatorAmount(amount);
  }

  @Mutation
  setTerm(term: number) {
    this.term = term;
    setCalculatorTerm(term);
  }

  @Mutation
  private setProducts(products: Product[]) {
    this.products = products;
  }

  @Action({ rawError: true })
  private setInitialSliderValues() {
    const product = this.selectedProduct;

    if (!product) {
      this.resetSliders();
      return;
    }

    const settings = this.sliderSetting;

    if (this.amount < settings.amount.min && this.amount > settings.amount.max) {
      this.setAmount(settings.amount.max);
    }

    if (!this.term || !settings.term.includes(this.term)) {
      this.setTerm(settings.term[settings.term.length - 1]);
    }
  }

  @Mutation
  chooseProduct(product: PRODUCT) {
    this.selectedProductType = product;
  }

  @Action({ rawError: true })
  resetSliders() {
    const amount = this.sliderSetting.amount;
    const initial = DEFAULT_MIN_CALCULATOR_VALUE;
    this.setAmount(initial >= amount.min && initial <= amount.max ? initial : amount.max);
    this.setTerm(this.sliderSetting.term[this.sliderSetting.term.length - 1]);
  }

  @Action({ rawError: true })
  async initProducts(context = { force: false }) {
    if (this.products.length && !context.force) {
      return true;
    }

    try {
      const products = await Promise.all([getProduct(PRODUCT.REGULAR), getProduct(PRODUCT.INSURANCE)]);

      this.setProducts(products.map(resp => resp.data));
      this.setInitialSliderValues();
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}

export const calculator = getModule(Calculator);
