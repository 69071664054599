import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { AgreementsState, InitAgreementsList } from '@/store/modules/models/agreements';
import {
  Agreement,
  AGREEMENT_CONTRACT_STATUS,
  AGREEMENT_PROCESSING_STATUS,
  AgreementDocument
} from '@/api/models/agreements';
import { getAgreementById, getAgreementList } from '@/api/agreements';

@Module({ name: 'agreements', store, dynamic: true })
export class Agreements extends VuexModule implements AgreementsState {
  private loaded = false;
  private agreements: Agreement[] = [];
  private documents: AgreementDocument[] = [];

  get getAgreements() {
    return this.agreements;
  }

  get lastAgreement() {
    return this.agreements[0];
  }

  get getActiveAgreements(): Agreement[] {
    return this.agreements.filter(agreement => agreement.processingStatus === AGREEMENT_PROCESSING_STATUS.ACTIVE);
  }

  get getAgreementDocuments() {
    return this.documents;
  }

  get getAgreementInProgress() {
    return this.agreements.find(agreement => {
      const notDraft = agreement.contractStatus !== AGREEMENT_CONTRACT_STATUS.DRAFT;
      const notIssued = agreement.contractStatus !== AGREEMENT_CONTRACT_STATUS.ISSUED;
      const notClosed = agreement.processingStatus !== AGREEMENT_PROCESSING_STATUS.CLOSED;

      return notDraft && notIssued && notClosed;
    });
  }

  get getPreparedAgreement() {
    return this.agreements.find(agreement => {
      return agreement.contractStatus === AGREEMENT_CONTRACT_STATUS.PREPARED;
    });
  }

  @Mutation
  setAgreements(agreements: Agreement[]) {
    this.agreements = agreements;
  }

  @Mutation
  setDocuments(documents: AgreementDocument[]) {
    this.documents = documents;
  }

  @Action
  async initPreparedAgreementDocuments() {
    const preparedAgreement = this.getPreparedAgreement;
    if (preparedAgreement) {
      try {
        const { data: agreement } = await getAgreementById(preparedAgreement.id);
        this.setDocuments(agreement.documents);

        return true;
      } catch (e) {
        return false;
      }
    }
  }

  @Action
  async initGetAgreementList(context: InitAgreementsList = { force: false }) {
    if (this.loaded && !context.force) {
      return true;
    }

    try {
      const { data: agreements } = await getAgreementList();

      this.setAgreements(agreements);
      this.setLoaded(true);

      return true;
    } catch (e) {
      return false;
    }
  }

  @Action
  reset() {
    this.setAgreements([]);
    this.setDocuments([]);
    this.setLoaded(false);
  }

  @Mutation
  private setLoaded(loaded: boolean) {
    this.loaded = loaded;
  }
}

export const agreements = getModule(Agreements);
