import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import * as guards from './guards';
import { routes } from '@/router/routes';

Vue.use(VueRouter);

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes,
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      } else if (to.hash) {
        return {
          selector: to.hash
        };
      } else {
        return scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      }
    }
  });

interface Redirect {
  name: string;
}

export type middlewareResult = Redirect | undefined | void;
export type middleware = () => middlewareResult;
export type asyncMiddleware = (context: { to: Route; from: Route }) => Promise<middlewareResult>;

const router = createRouter();

Object.values(guards).forEach(guard => router.beforeEach(guard));

export default router;
