import { ApplicationClientInput, ApplicationClientInputMetadata } from '@/api/models/application';
import { ActivityData } from '@/api/models/document';
import { FieldData } from '@/components/registration/models/field';

enum AGREEMENT_AMENDMENT_REASON {
  OUTDATED_SCHEDULE = 'outdated_schedule'
}

export const enum AGREEMENT_CONTRACT_STATUS {
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  PREPARED = 'prepared',
  SIGNED = 'signed',
  APPROVED = 'approved',
  REGISTERED = 'registered',
  CANCELLED = 'cancelled',
  REJECTED = 'rejected',
  ISSUED = 'issued',
  REPAID = 'repaid',
  TERMINATED = 'terminated',
  ABORTED = 'aborted'
}

export const enum AGREEMENT_PROCESSING_STATUS {
  PENDING = 'pending',
  ACTIVE = 'active',
  CLOSED = 'closed'
}

interface AgreementAmendment {
  scheduleId: string;
  number: string;
  reason: AGREEMENT_AMENDMENT_REASON;
}

export const ONLINE_SIGNING_TYPE = 'online';

export interface Agreement {
  id: string;
  loanId: string;
  number: string;
  amendments: AgreementAmendment[];
  contractStatus: AGREEMENT_CONTRACT_STATUS;
  processingStatus: AGREEMENT_PROCESSING_STATUS;
  calculatorInput: ApplicationClientInputMetadata;
  applicationId: string;
  productId: string;
  companyId: string;
  clientId: string;
  amendment: AgreementAmendment;
  scheduleId: string;
  agreementId: string;
  features: string[];
  signingMethod?: AgreementSigningMethod;
  documents: AgreementDocument[];
}

export interface AgreementDocument {
  documentId: string;
  type: string;
  details: AgreementDocumentSignDetails;
}

export interface AgreementDocumentSignDetails {
  signed: boolean;
  details?: SignOnlineSignDetails;
}

export interface SignOnlineSignDetails {
  signOnlineId: string;
  signOnlineLink: string;
}

export interface AgreementSigningMethod {
  type: string;
  data?: FieldData;
}

const enum PAYMENT_COMPONENT_CODE {
  PRINCIPAL_CAPITALIZED = 'principal_capitalized',
  PRINCIPAL_BASE = 'principal_base',
  INTEREST_FEE = 'interest_fee'
}

interface PaymentComponent {
  amount: string;
  code: PAYMENT_COMPONENT_CODE;
  allocation: string[] | null;
}

interface AgreementSchedulePayment {
  number: number;
  startDate: string;
  endDate: string;
  totalCommissionsAmount: string;
  totalPaymentAmount: string;
  principalAmount: string;
  paymentAmount: string;
  totalRegularAmount: string;
  totalPrincipalAmount: string;
  components: PaymentComponent[];
  instalmentId: string | null;
  activated: ActivityData | null;
  registered: ActivityData | null;
  paid: ActivityData | null;
  cancelled: ActivityData | null;
}

interface AgreementScheduleComputations {
  annualEffectiveYield: string;
  dailyNominalRate: string;
  monthlyNominalRate: string;
  annualNominalRate: string;
  totalRepayableAmount: string;
  capitalizedAmount: string;
  costOfCredit: string;
  issuedAmount: string;
  principalAmount: string;
  totalPrincipalAmount: string;
}

const enum RATE_CODE {
  INSURANCE_FEE = 'insurance_fee',
  PRINCIPAL_CAPITALIZED = 'principal_capitalized',
  PRINCIPAL_BASE = 'principal_base',
  DELAY_DAILY_FEE = 'delay_daily_fee'
}

const enum RATE_TYPE {
  CAPITALIZED = 'capitalized',
  PRINCIPAL = 'principal',
  PENALTY = 'penalty'
}

interface ScheduleRate {
  rate: string;
  code: RATE_CODE;
  type: RATE_TYPE;
}

const enum SCHEDULE_COMPONENT_TOTALS_CODE {
  PRINCIPAL_CAPITALIZED = 'principal_capitalized',
  PRINCIPAL_BASE = 'principal_base',
  INTEREST_FEE = 'interest_fee'
}

const enum SCHEDULE_COMPONENT_TOTALS_TYPE {
  PRINCIPAL = 'principal',
  REGULAR = 'regular'
}

interface ScheduleComponentTotals {
  amount: string;
  code: SCHEDULE_COMPONENT_TOTALS_CODE;
  type: SCHEDULE_COMPONENT_TOTALS_TYPE;
}

export interface AgreementSchedule {
  id: string;
  agreementId: string;
  loanId: string;
  clientId: string;
  computations: AgreementScheduleComputations;
  applicationInput: ApplicationClientInput;
  payments: AgreementSchedulePayment[];
  productId: string;
  calculatorInput: ApplicationClientInputMetadata;
  rates: ScheduleRate[];
  capitalizedAmounts: [];
  componentTotals: ScheduleComponentTotals[];
  created: ActivityData;
  updated: ActivityData;
  replaced: ActivityData | null;
}

export interface AgreementRequestList {
  productId?: string;
  applicationId?: string;
  loanId?: string;
  companyId?: string;
  scheduleId?: string;
  headId?: string;
  paymentId?: string;
  paymentOrderId?: string;
  number?: string;
  contractStatus?: AGREEMENT_CONTRACT_STATUS;
  processingStatus?: AGREEMENT_PROCESSING_STATUS;
}
