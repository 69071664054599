
import { Vue, Component } from 'vue-property-decorator';
import LmsCalculatorSlider from '@/components/calculator/CalculatorSlider.vue';
import LmsCalculatorMetrixSlider from '@/components/calculator/CalculatorMetrixSlider.vue';
import { calculator } from '@/store/modules/calculator';

@Component({
  name: 'LmsCalculatorLight',
  components: {
    LmsCalculatorSlider,
    LmsCalculatorMetrixSlider
  }
})
export default class CalculatorComponent extends Vue {
  get amount() {
    return calculator.amount ?? 0;
  }

  get term() {
    return calculator.term ?? 0;
  }

  get sliderSettings() {
    return calculator.sliderSetting;
  }

  setAmount(amount: number) {
    calculator.setAmount(amount);
  }

  setTerm(term: number) {
    calculator.setTerm(term);
  }
}
