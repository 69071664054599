
import { Component, Vue } from 'vue-property-decorator';
import VueScrollTo from 'vue-scrollto';
import Logo from '@/components/Logo.vue';

@Component({
  name: 'Footer',
  components: { Logo }
})
export default class FooterComponent extends Vue {
  scrollTo(element: string) {
    VueScrollTo.scrollTo(element, 700, {
      easing: 'ease-in'
    });
  }
}
