export enum DISBURSEMENT_TYPE {
  BANK_ACCOUNT = 'bank_account',
  BANK_CARD = 'card'
}

export type DisbursementCardData = {
  cardHolder: string;
  cardId: number;
  panMasked: string;
  userId: number;
  userLogin: string;
};

type DisbursementBankAccountData = {
  bankAccountNumber: string;
};

export type DisbursementCard = {
  type: DISBURSEMENT_TYPE.BANK_CARD;
  data: DisbursementCardData;
};

export type DisbursementBankAccount = {
  type: DISBURSEMENT_TYPE.BANK_ACCOUNT;
  data: DisbursementBankAccountData;
  markAsCurrent: boolean;
};

export type ClientDisbursement = DisbursementCard | DisbursementBankAccount;
