
import { Vue, Component, Ref } from 'vue-property-decorator';
import LmsForm from '@/components/registration/Form.vue';
import LmsInput from '@/components/registration/FieldInput.vue';
import { client } from '@/store/modules/client';
import { Login } from '@/api/models/login';
import { loginData } from '@/views/Login';
import { LmsFieldState } from '@/components/registration/models/field';
import { APPLICATION_ROUTES, REGISTRATION_ROUTES, ROUTE, PROFILE_ROUTES } from '@/router/routes_type';
import { getRoute } from '@/router/router';
import { FORM_ERRORS } from '@/components/registration/settings/rules';

const NOT_REDIRECTABLE_PAGES: ROUTE[] = [REGISTRATION_ROUTES.APPLICATION_REJECT_PAGE];

@Component({
  components: { LmsForm, LmsInput }
})
export default class LoginPage extends Vue {
  @Ref() username!: LmsFieldState[];
  @Ref() password!: LmsFieldState[];

  loginFields = loginData;
  loading = false;
  registrationRoute = REGISTRATION_ROUTES.PERSONAL_DATA;
  forgotPassword = APPLICATION_ROUTES.PASSWORD_FORGOT;

  get helpLink() {
    return process.env.VUE_APP_BASE_HELP_LINK;
  }

  async login(payload: Login) {
    this.loading = true;

    try {
      await client.initLogin(payload);

      this.loading = false;
      let route = await getRoute();

      if (NOT_REDIRECTABLE_PAGES.includes(route)) {
        route = PROFILE_ROUTES.MY_LOAN_PAGE;
      }

      this.$router.push({ name: route }).catch(e => console.error(e));
    } catch (e) {
      this.loading = false;

      switch (e.status) {
        case 401:
          this.password[0].error = FORM_ERRORS.FIELD_ERROR_ID_NUMBER_NOT_EXIST;
          break;
        case 403:
          this.password[0].error = FORM_ERRORS.FIELD_ERROR_CLIENT_IS_BLOCKED;
          break;
        default:
          this.password[0].error = FORM_ERRORS.FIELD_ERROR_DEFAULT;
      }
    }
  }
}
