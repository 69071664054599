import request, { HTTP_METHODS } from '@/utils/request';
import { AxiosPromise } from 'axios';
import { stringify } from 'qs';
import { DebtSettlementApplication, DebtSettlementApplicationInput } from '@/api/models/debtSettlementApplication';

const PER_PAGE = 100;

export const getApplications = (): AxiosPromise<DebtSettlementApplication[]> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/application/simple-applications',
    method: HTTP_METHODS.GET,
    params: { type: 'debt-settlement', perPage: PER_PAGE },
    paramsSerializer: stringify
  });

export const createApplication = (data: DebtSettlementApplicationInput): AxiosPromise<DebtSettlementApplication> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/application/simple-applications',
    method: HTTP_METHODS.POST,
    data: { ...data, workflowAlias: 'debt-settlement' }
  });

export const transitApplication = (id: string, action: string): AxiosPromise<DebtSettlementApplication> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: `/application/simple-applications/${id}/transit`,
    method: HTTP_METHODS.PUT,
    data: { action: action }
  });
