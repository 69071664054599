import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { Content, ContentState, SectionContent, SECTIONS } from '@/store/modules/models/content';
import { LOCALES } from '@/api/models/common';
import i18n from '@/core/plugins/vue-i18n';
import { CONTENT_LOCALES, getContentSectionKeys } from '@/api/content';

@Module({ dynamic: true, store, name: 'content' })
class ContentStore extends VuexModule implements ContentState {
  content = Object.values(LOCALES).reduce<Content>((result, locale) => {
    result[locale] = {};
    return result;
  }, {});

  @Mutation
  private SET_SECTION(payload: { locale: LOCALES; section: SECTIONS; content: SectionContent }) {
    this.content[payload.locale] = {
      ...this.content[payload.locale],
      [payload.section]: payload.content
    };
  }

  @Action
  public async loadContentSection(payload: { locale: LOCALES; section: SECTIONS }): Promise<void> {
    if (this.contentSection(payload.section, payload.locale)) {
      return;
    }
    const { data: content } = await getContentSectionKeys(CONTENT_LOCALES[payload.locale], payload.section);
    this.SET_SECTION({ locale: payload.locale, section: payload.section, content });
  }

  get contentSection() {
    return <T = string>(section: SECTIONS, locale: LOCALES = i18n.locale) => {
      const localeSections = this.content[locale];
      if (localeSections) {
        return localeSections[section] as SectionContent<T>;
      }

      return null;
    };
  }
}

export const content = getModule(ContentStore);
