
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'Card'
})
export default class CardComponent extends Vue {
  @Prop({ required: true }) private icon!: string;
  @Prop({ required: true }) private iconHover!: string;
  @Prop({ required: true }) private label!: string;
  @Prop() private active: boolean;
}
