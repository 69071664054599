
import { Prop, Component, Mixins, Watch } from 'vue-property-decorator';
import Field from '@/components/registration/Field.vue';

type Marks = { [key: string]: string };

interface CalculatorMetrixSlider {
  settings: number[];
  min: number;
  max: number;
  step: number;
  model: number;
  label: string;
  marks: Marks;
}

@Component({
  name: 'LmsCalculatorMetrixSlider'
})
export default class CalculatorMetrixSliderComponent extends Mixins(Field) implements CalculatorMetrixSlider {
  @Prop({ required: true })
  settings!: number[];
  @Prop({ default: 'Label Text' })
  label!: '';
  @Prop({ default: false })
  disabled!: false;

  min = 0;
  step = 1;

  set model(idx: number) {
    this.value = this.settings[idx];
  }

  get model() {
    const idx = this.settings.findIndex(term => term === this.value);

    return idx !== -1 ? idx : this.max;
  }

  get marks(): Marks {
    const marks: Marks = { '0': '0' };
    return this.settings.reduce((total, current) => {
      total[this.settings.findIndex(term => term === current).toString()] = current.toString();
      return total;
    }, marks);
  }

  get active() {
    return this.settings.length > 1;
  }

  get max() {
    return Math.max(0, this.settings.length - 1);
  }

  @Watch('settings', { deep: true })
  setMaxTerm() {
    this.model = this.max;
  }
}
