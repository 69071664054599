
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Article } from '@/components/NewsArticle/types';

@Component({
  name: 'NewsArticle'
})
export default class NewsArticle extends Vue {
  @Prop({ required: true }) article!: Article;
}
