
import { Component, Prop, Vue } from 'vue-property-decorator';
import Logo from '@/components/Logo.vue';
import { client } from '@/store/modules/client';
import { LOCALES } from '@/api/models/common';
import i18n, { switchLocale } from '@/core/plugins/vue-i18n';
import { APPLICATION_ROUTES } from '@/router/routes_type';

@Component({
  name: 'HeaderComponent',
  components: { Logo }
})
export default class HeaderComponent extends Vue {
  @Prop({ default: false }) private transparent: boolean;
  @Prop({ default: 'default' }) private type!: string;
  private locales = LOCALES;
  private selectedLanguage: LOCALES = i18n.locale;
  private client = client;
  private drawer = false;

  private telNumber: string = String(this.$t(`MENU.PHONE_NR`)).replace(/\D/g, '');
  private telLink = `tel:+${this.telNumber}`;

  loginPage = { name: APPLICATION_ROUTES.LOGIN_PAGE };

  closeDrawer() {
    this.drawer = false;
  }

  onLanguageSelect(locale: LOCALES) {
    this.selectedLanguage = locale;
    switchLocale(locale);
  }
}
