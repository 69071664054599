// Sections
import SectionMain from './sections/SectionMain.vue';
import SectionAnyGoals from './sections/SectionAnyGoals.vue';
import SectionBusiness from './sections/SectionBusiness.vue';
import SectionFaq from './sections/SectionFaq.vue';
import SectionWhySalem from './sections/SectionWhySalem.vue';
import SectionQuestionnaire from './sections/SectionQuestionnaire.vue';
import SectionAbout from './sections/SectionAbout.vue';

export const sections = {
  SectionMain,
  SectionAnyGoals,
  SectionBusiness,
  SectionFaq,
  SectionWhySalem,
  SectionQuestionnaire,
  SectionAbout
};

// Transitions
import TransitionSharp from './transitions/TransitionSharp.vue';
import TransitionRound from './transitions/TransitionRound.vue';

export const transitions = { TransitionSharp, TransitionRound };
