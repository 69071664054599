export const enum PRODUCT {
  REGULAR = 'salem',
  INSURANCE = 'salem-plus'
}

export const DATE_PATTERN = 'YYYY-MM-DD';

export const DATE_PATTERN_TO_SHOW = 'DD/MM/YYYY';

export const DATE_TIME_PATTERN_TO_SHOW = 'DD/MM/YYYY HH:mm';

export const CURRENCY_SYMBOL = '₸';
