import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Localisation language list
import { locale as ru } from '@/core/config/i18n/ru.ts';
import { locale as kk } from '@/core/config/i18n/kz.ts';
import elementRu from 'element-ui/lib/locale/lang/ru-RU';
import elementKz from 'element-ui/lib/locale/lang/kz';
import { CURRENT_LOCALE, setLocale } from '@/utils/cookies';
import { LOCALES } from '@/api/models/common';

Vue.use(VueI18n);

const messages = {
  ru: {
    ...ru,
    ...elementRu
  },
  kk: {
    ...kk,
    ...elementKz
  }
};

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: CURRENT_LOCALE, // set locale
  messages // set locale messages
});

export const switchLocale = (locale: LOCALES) => {
  i18n.locale = locale;
  setLocale(locale);
};

export default i18n;
