export const enum APPLICATION_ROUTES {
  HOME_PAGE = 'Home',
  LOGIN_PAGE = 'LoginPage',
  PASSWORD_FORGOT = 'PasswordForgot'
}

export const enum PROFILE_ROUTES {
  MY_LOAN_PAGE = 'ProfileMyLoanPage',
  DOCUMENTS_PAGE = 'ProfileDocumentsPage',
  CHANGE_PASSWORD_PAGE = 'ProfileChangePasswordPage',
  CHANGE_EMAIL_PAGE = 'ProfileChangeEmailPage',
  CHANGE_LOCALE = 'ProfileChangeLocalePage',
  CHANGE_PHONE_PAGE = 'ProfileChangePhonePage',
  NO_LOAN_PAGE = 'ProfileNoLoanPage',
  EDIT_PAGE = 'ProfileEditPage',
  CREATE_DEBT_SETTLEMENT_APPLICATION = 'CreateDebtSettlementApplicationPage',
  EDIT_DEBT_SETTLEMENT_APPLICATION = 'EditDebtSettlementApplicationPage',
  DEBT_SETTLEMENT_LIST = 'DebtSettlementList'
}

export const enum REGISTRATION_ROUTES {
  PERSONAL_DATA = 'RegistrationPersonalData',
  ADDITIONAL_INFO = 'RegistrationAdditionalInfo',
  CALCULATOR = 'RegistrationCalculator',
  DOCUMENTS_PAGE = 'RegistrationDocumentsPage',
  DISBURSEMENT_TYPE_PAGE = 'RegistrationDisbursementTypePage',
  SUMMARY_PAGE = 'RegistrationSummaryPage',
  OFFER_DECREASE_PAGE = 'RegistrationOfferDecreasePage',
  OFFER_INCREASE_PAGE = 'RegistrationOfferIncreasePage',
  DECISION_PAGE = 'RegistrationDecisionPage',
  MANUAL_VERIFICATION_PAGE = 'RegistrationManualVerificationPage',
  AGREEMENT_SIGNING_PAGE = 'RegistrationAgreementSigningPage',
  AGREEMENT_ONLINE_SIGNING_PAGE = 'RegistrationAgreementOnlineSigningPage',
  APPLICATION_REJECT_PAGE = 'RegistrationApplicationRejectPage',
  VERIGRAM = 'Verigram',
  INCOME_REUPLOAD_PAGE = 'IncomeReuploadPage'
}

export const enum MULTI_LOAN_ROUTES {
  DISBURSEMENT_TYPE_PAGE = 'MultiLoanDisbursementTypePage',
  INCOME_PAGE = 'MultiLoanIncomeInfoPage',
  SUMMARY_PAGE = 'MultiLoanSummaryPage',
  CALCULATOR = 'MultiLoanCalculatorPage'
}

export type ROUTE = APPLICATION_ROUTES | PROFILE_ROUTES | REGISTRATION_ROUTES | MULTI_LOAN_ROUTES;
