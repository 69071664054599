import { loader } from '@/utils/loader';
import { v4 as uuidv4 } from 'uuid';

import { VerificationControllerClass } from '@/services/classes/verificationControllerClass';
import {
  VERIFICATION_CHANNEL,
  VERIFICATION_TYPE,
  VerificationResponse,
  VerificationSubject
} from '@/api/models/verification';
import { Route } from 'vue-router';
import { DISBURSEMENT_TYPE, DisbursementCard, DisbursementCardData } from '@/api/models/disbursement';
import { Verification } from '@/services/classes/verification';
import i18n from '@/core/plugins/vue-i18n';

export enum VERIFICATION_CARD_QUERY {
  ID = 'verificationId',
  AMOUNT = 'testAmount',
  MASK = 'panMasked'
}

type TBankCardVerificationAttributes = {
  redirectUrl: string;
  userId: number;
  userLogin: string;
};

export type TBankCardVerificationValue = DisbursementCardData;

export type TBankCardVerificationStamp = TBankCardVerificationValue & {
  testAmount: boolean;
  customerId?: string;
};

export type TBankCardVerificationState = VerificationResponse & {
  receiptAttributes: TBankCardVerificationAttributes | null;
  receiptStamp: TBankCardVerificationStamp | null;
};

export type TBankCardVerification = Verification<TBankCardVerificationState>;

class BankCardVerificationServiceClass extends VerificationControllerClass<TBankCardVerificationState> {
  subject: VerificationSubject;
  channel: VERIFICATION_CHANNEL.ONE_CLICK;

  async initVerification(redirectPath: string) {
    this.subject = {
      identity: uuidv4(),
      type: VERIFICATION_TYPE.BANK_CARD_CONFIRMATION,
      attributes: {
        language: i18n.locale,
        redirectPath
      }
    };
    await this.createVerification(this.subject, VERIFICATION_CHANNEL.ONE_CLICK);
    const redirectTo = this.verificationStore?.response.receiptAttributes?.redirectUrl;
    if (redirectTo) {
      loader.show();
      window.location.href = redirectTo;
    }

    return this.verificationStore;
  }

  catchVerificationId(route: Route) {
    return route.params.verificationId || (route.query[VERIFICATION_CARD_QUERY.ID] as string);
  }

  async getVerificationOnRedirect(route: Route) {
    this.removeVerification();
    const verificationId = this.catchVerificationId(route);

    if (verificationId) {
      const verification = await this.restoreVerification(verificationId, VERIFICATION_CHANNEL.ONE_CLICK);
      if (!verification) {
        return false;
      }

      const noAmountCheck = !this.hasTestAmountCheck;
      const canBeConfirmed = !verification?.isConfirmed && !verification?.isExpired;
      if (noAmountCheck && canBeConfirmed) {
        // no need for additional test
        await verification.verify({});
      }
    }

    return Boolean(verificationId);
  }

  removeVerification() {
    this.verificationStore && this.verificationStore.removeVerification();
    this.destroyVerification();
  }

  get stamp() {
    return this.verification?.response?.receiptStamp;
  }

  get mask() {
    return this.stamp?.panMasked;
  }

  get hasTestAmountCheck(): boolean {
    return !!this.stamp?.testAmount;
  }

  get data() {
    if (this.stamp) {
      const { cardId, cardHolder, panMasked, userId, userLogin } = this.stamp || {};
      return { cardId, cardHolder, panMasked, userId, userLogin };
    }
  }

  get disbursement(): DisbursementCard | null {
    return this.data
      ? {
        type: DISBURSEMENT_TYPE.BANK_CARD,
        data: this.data
      }
      : null;
  }
}

export const BankCardVerificationService = new BankCardVerificationServiceClass();
