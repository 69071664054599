import { NavigationGuard } from 'vue-router';
import { storeMarketingMetrics } from '@/utils/marketing';

export interface QueryParams {
  [key: string]: string | (string | null)[];
}

export const storeMarketingMetricsQueryParams: NavigationGuard = async (to, from, next) => {
  const queryParams: QueryParams = to.query;
  const queryParamsKeys: string[] = Object.keys(queryParams);
  if (queryParamsKeys.some(key => key.toLowerCase().startsWith('utm_'))) {
    storeMarketingMetrics(queryParams);
  }

  next();
};
