
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'section-scroll'
})
export default class SectionScrollComponent extends Vue {
  private observer: IntersectionObserver = new IntersectionObserver(this.onElementObserved);
  private refList: HTMLElement[] = [];
  private visibleSections: number[] = [1];

  get isMoveUpAvailable() {
    return this.visibleSections.length > 0 && this.visibleSections[0] !== 1;
  }

  get isMoveDownAvailable() {
    return this.visibleSections.length > 0 && this.visibleSections[0] !== 9;
  }

  mounted() {
    const refs = this.$parent?.$refs ?? {};
    this.refList = Object.keys(refs).map((refName: string) => {
      const refElement = (refs as any)[refName].$el;
      this.observer.observe(refElement);
      return refElement;
    });
  }

  onElementObserved(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (entry.target.id && entry.target.id.startsWith('scroll-section-')) {
        const id: number = Number.parseInt(entry.target.id.split('-')[2]);
        if (entry.isIntersecting && this.visibleSections.indexOf(id) === -1) {
          if (this.visibleSections.length < 1 || id > this.visibleSections[this.visibleSections.length - 1]) {
            this.visibleSections.push(id);
          } else this.visibleSections.unshift(id);
        } else if (!entry.isIntersecting && this.visibleSections.indexOf(id) !== -1) {
          this.visibleSections.splice(this.visibleSections.indexOf(id), 1);
        }
      }
    });
  }

  beforeDestroy() {
    this.observer.disconnect();
  }

  scroll(isDown: boolean) {
    if ((isDown && this.isMoveDownAvailable) || (!isDown && this.isMoveUpAvailable)) {
      const scrollToId = `scroll-section-${this.visibleSections[0] + (isDown ? 1 : -1)}`;
      this.refList.find((refElement: HTMLElement) => {
        if (refElement.id === scrollToId) window.scrollTo({ top: refElement.offsetTop + 1, behavior: 'smooth' });
      });
    } else {
      window.scrollTo({
        top: isDown ? document.body.scrollHeight - window.innerHeight : 0,
        behavior: 'smooth'
      });
    }
  }
}
