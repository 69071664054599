
import { Component, Vue } from 'vue-property-decorator';
import SectionQuestionnaire from '@/components/layout/sections/SectionQuestionnaire.vue';
import { APPLICATION_ROUTES } from '@/router/routes_type';
@Component({
  components: { SectionQuestionnaire }
})
export default class CreditForBusinessPage extends Vue {
  loginPage = { name: APPLICATION_ROUTES.LOGIN_PAGE };
}
