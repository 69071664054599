import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
  AddressResponse,
  createClient,
  getDisbursementType,
  getProfile,
  login,
  refreshTokens,
  saveAdditionalInfo,
  saveAddress,
  saveIdentityDocuments,
  updatePersonalInfo
} from '@/api/client';
import { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken } from '@/utils/cookies';
import qs from 'qs';
import {
  AdditionalInfo as AdditionalInfoModel,
  AdditionalInfo,
  AddressesMap,
  BankStatement,
  ClientCreatePayload,
  DisbursementsResponseMap,
  FinancesData,
  PersonalData,
  Profile,
  PROFILE_ADDITIONAL_INFO_KEYS,
  PROFILE_SECTIONS,
  ProfileDesiredLoanInterface,
  ProfileWorkInfoInterface,
  TOKENS,
  Tokens,
  VERIFICATION_STATUS
} from '@/api/models/client';
import { AUTO_LOGIN_TOKENS, ClientState } from '@/store/modules/models/client';
import { Login } from '@/api/models/login';
import VueJwtDecode from 'vue-jwt-decode';
import router from '@/router';
import { APPLICATION_ROUTES } from '@/router/routes_type';
import { DISBURSEMENT_TYPE, DisbursementCard } from '@/api/models/disbursement';
import store, { Payload } from '@/store';
import { resetState } from '@/store/state';
import { LOGOUT } from '@/router/routes';
import { cloneDeep, isEmpty } from 'lodash-es';
import { IDENTITY_DOCUMENT_TYPE, IdentityDocument } from '@/api/models/itentityDocument';
import { Address, ADDRESS_TYPE } from '@/api/models/address';
import { AddressService } from '@/services/address';
import { CONTENT_LOCALES } from '@/api/content';

const NULL_CLIENT_PROFILE: Profile = {
  id: '',
  companyId: '',
  username: '',
  email: '',
  usernameVerificationStatus: VERIFICATION_STATUS.UNKNOWN,
  mobilePhone: '',
  emailVerified: false,
  mobilePhoneVerified: false,
  livelinessVerificationStatus: VERIFICATION_STATUS.UNKNOWN,
  availableCredit: null,
  locale: CONTENT_LOCALES.kk,
  personalInfo: {
    name: {
      firstName: '',
      lastName: '',
      middleName: ''
    },
    gender: '',
    birthDate: ''
  },
  additionalInfo: {
    [PROFILE_ADDITIONAL_INFO_KEYS.EDUCATION]: undefined,
    [PROFILE_ADDITIONAL_INFO_KEYS.WORK_INFO]: undefined,
    [PROFILE_ADDITIONAL_INFO_KEYS.FAMILY]: undefined,
    [PROFILE_ADDITIONAL_INFO_KEYS.DESIRED_LOAN]: undefined
  },
  creditLimit: {
    maxAmount: '',
    maxPayment: ''
  },
  addresses: [],
  identityDocuments: []
};

const emptyWorkInfoFactory = () => ({
  employmentStatus: ''
});

const emptyBankStatementFactory = () => ({
  startDate: '',
  endDate: ''
});

const emptyFamilyAdditionalInfoFactory = (): AdditionalInfo => ({
  additionalContactName: '',
  maritalStatus: '',
  numberOfChildren: ''
});

const emptyIdentityDocumentFactory = (): IdentityDocument => ({
  type: IDENTITY_DOCUMENT_TYPE.NATIONAL_ID,
  current: false,
  number: '',
  issuedAt: '',
  expiresAt: '',
  issuedBy: ''
});

const emptyPersonalDataFactory = (): PersonalData => ({
  personalInfo: {
    name: {
      firstName: '',
      lastName: '',
      middleName: ''
    },
    birthDate: '',
    gender: ''
  },
  identityDocument: emptyIdentityDocumentFactory()
});

export function toPersonalData(profile: Profile): PersonalData {
  const passport = profile.identityDocuments?.find(document => {
    return document.type === IDENTITY_DOCUMENT_TYPE.NATIONAL_ID && document.current;
  });

  return { personalInfo: profile.personalInfo, identityDocument: passport ?? emptyIdentityDocumentFactory() };
}

const addressFilled = (address: null | Address): boolean => {
  if (!address) {
    return false;
  }

  return ![
    address.type,
    address.street,
    address.city,
    address.fields.house,
    address.fields.region,
    address.fields.regionKatoId
  ].some(isEmpty);
};

export function toAddresses(addressesResponse: AddressResponse[]): AddressesMap {
  const addresses = emptyAddressMapFactory();

  addressesResponse.forEach(address => {
    if (address.current) {
      addresses[address.type] = address as Address;
    }
  });

  if (addresses.declared && addresses.living) {
    addresses.matchesDeclaredAddress =
      addresses.declared.fields.regionKatoId === addresses.living?.fields.regionKatoId &&
      addresses.declared.fields.region === addresses.living?.fields.region &&
      addresses.declared.city === addresses.living?.city &&
      addresses.declared.street === addresses.living?.street &&
      addresses.declared.fields.house === addresses.living?.fields.house &&
      addresses.declared.fields.apartment === addresses.living?.fields.apartment;
  }

  return addresses;
}

const emptyAddressFactory = (type: ADDRESS_TYPE): Address => ({
  city: '',
  fields: {
    apartment: '',
    house: '',
    region: '',
    regionKatoId: ''
  },
  street: '',
  type: type
});

const emptyAddressMapFactory = (): AddressesMap => ({
  declared: emptyAddressFactory(ADDRESS_TYPE.DECLARED),
  living: emptyAddressFactory(ADDRESS_TYPE.LIVING),
  matchesDeclaredAddress: true
});

const clientDisbursementMapInitialState = () => ({
  [DISBURSEMENT_TYPE.BANK_ACCOUNT]: [],
  [DISBURSEMENT_TYPE.BANK_CARD]: []
});

const ON = '1';

@Module({ dynamic: true, store, name: 'client' })
class Client extends VuexModule implements ClientState {
  accessToken = getAccessToken() || '';
  refreshToken = getRefreshToken() || '';
  profile: Profile = NULL_CLIENT_PROFILE;

  get authorized() {
    return !!this.profile.id;
  }

  get username() {
    return this.profile.username || '';
  }

  get firstName() {
    return this.profile.personalInfo.name.firstName || '';
  }

  get lastName() {
    return this.profile.personalInfo.name.lastName || '';
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`.trim();
  }

  get availableCredit() {
    return this.profile.availableCredit;
  }

  get desiredLoan(): ProfileDesiredLoanInterface | null {
    return this.profile.additionalInfo[PROFILE_ADDITIONAL_INFO_KEYS.DESIRED_LOAN] ?? null;
  }

  // Family additional info

  get familyAdditionalInfo(): AdditionalInfo {
    return this.profile.additionalInfo?.[PROFILE_ADDITIONAL_INFO_KEYS.FAMILY] ?? emptyFamilyAdditionalInfoFactory();
  }

  get familyAdditionalInfoFilled(): boolean {
    return ![
      this.familyAdditionalInfo.additionalContactName,
      this.familyAdditionalInfo.maritalStatus,
      this.familyAdditionalInfo.numberOfChildren
    ].some(isEmpty);
  }

  @Action({ rawError: true })
  async updateFamilyAdditionalInfo(data: AdditionalInfoModel) {
    await saveAdditionalInfo({ data: { [PROFILE_ADDITIONAL_INFO_KEYS.FAMILY]: data } });
  }

  // Personal data

  get personalData(): PersonalData {
    return toPersonalData(this.profile) ?? emptyPersonalDataFactory();
  }

  get personalDataFilled(): boolean {
    return ![
      this.personalData.personalInfo.name.firstName,
      this.personalData.personalInfo.name.lastName,
      this.personalData.personalInfo.birthDate,
      this.personalData.personalInfo.gender,
      this.personalData.identityDocument.number,
      this.personalData.identityDocument.issuedAt,
      this.personalData.identityDocument.expiresAt,
      this.personalData.identityDocument.issuedBy
    ].some(isEmpty);
  }

  @Action({ rawError: true })
  async updatePersonalData(data: PersonalData) {
    await updatePersonalInfo(data.personalInfo);
    await saveIdentityDocuments({ ...data.identityDocument, type: IDENTITY_DOCUMENT_TYPE.NATIONAL_ID });
  }

  // Finances data

  get financesData(): FinancesData {
    return { workInfo: this.workInfo, bankStatement: this.bankStatement };
  }

  get workInfo(): ProfileWorkInfoInterface {
    return this.profile.additionalInfo?.[PROFILE_ADDITIONAL_INFO_KEYS.WORK_INFO] ?? emptyWorkInfoFactory();
  }

  get bankStatement(): BankStatement {
    return this.profile.additionalInfo?.[PROFILE_ADDITIONAL_INFO_KEYS.BANK_STATEMENT] ?? emptyBankStatementFactory();
  }

  get financesDataFilled(): boolean {
    return this.workInfoFilled && this.bankStatementFilled;
  }

  get workInfoFilled(): boolean {
    return !isEmpty(this.financesData.workInfo.employmentStatus);
  }

  get bankStatementFilled(): boolean {
    return !(
      isEmpty(this.financesData.bankStatement.startDate) ||
      isEmpty(this.financesData.bankStatement.endDate) ||
      this.financesData.bankStatement.turnover === 0
    );
  }

  @Action({ rawError: true })
  async updateFinancesData(data: FinancesData) {
    await saveAdditionalInfo({ data: { [PROFILE_ADDITIONAL_INFO_KEYS.WORK_INFO]: data.workInfo } });
    await saveAdditionalInfo({ data: { [PROFILE_ADDITIONAL_INFO_KEYS.BANK_STATEMENT]: data.bankStatement } });
  }

  // Address

  get addresses(): AddressesMap {
    return toAddresses(this.profile.addresses ?? []);
  }

  get declaredAddress(): Address {
    return this.addresses.declared;
  }

  get addressesFilled(): boolean {
    return addressFilled(this.addresses.living) && addressFilled(this.addresses.declared);
  }

  @Action({ rawError: true })
  async updateAddressData(addresses: AddressesMap): Promise<void> {
    await this.saveAddress(addresses.declared);
    const living = cloneDeep(
      addresses.matchesDeclaredAddress ? addresses.declared : addresses.living ?? addresses.declared
    );
    living.type = ADDRESS_TYPE.LIVING;

    await this.saveAddress(living);
  }

  @Action
  private async saveAddress(address: Address | null): Promise<void> {
    if (address) {
      address.fields.region = (await AddressService.regionNameByKatoId(address.fields.regionKatoId)) ?? '';
      saveAddress(address);
    }
  }

  @Mutation
  setClientTokens(jwtTokens: Tokens) {
    this.accessToken = jwtTokens[TOKENS.ACCESS_TOKEN];
    this.refreshToken = jwtTokens[TOKENS.REFRESH_TOKEN] ?? '';
    setAccessToken(jwtTokens[TOKENS.ACCESS_TOKEN]);
    if (this.refreshToken) {
      setRefreshToken(this.refreshToken);
    }
  }

  @Mutation
  setClientProfile(profile: Profile) {
    this.profile = profile;
  }

  @Action({ rawError: true })
  async initCreateClient(createClientPayload: ClientCreatePayload) {
    const { data, headers } = await createClient(createClientPayload);

    this.setClientTokens({
      [TOKENS.ACCESS_TOKEN]: headers[AUTO_LOGIN_TOKENS.X_ACCESS_TOKEN],
      [TOKENS.REFRESH_TOKEN]: headers[AUTO_LOGIN_TOKENS.X_REFRESH_TOKEN]
    });

    this.setClientProfile(data);
  }

  @Action({ rawError: true })
  async initLogin(clientInfo: Login) {
    const { data } = await login(qs.stringify(clientInfo));
    this.setClientTokens(data);
  }

  @Action
  public async tokenExpired(token: string) {
    return Date.now() / 1000 > (await VueJwtDecode.decode(token).exp);
  }

  @Action
  async initRefreshTokens() {
    if (!this.refreshToken) {
      return false;
    }

    try {
      const { data } = await refreshTokens(this.refreshToken);

      this.setClientTokens(data);
      return true;
    } catch (e) {
      return false;
    }
  }

  @Action
  async initProfile(context: Payload = { force: false }) {
    if (this.accessToken === '') {
      return false;
    }

    if (this.authorized && !context.force) {
      return true;
    }

    try {
      const res = await getProfile({
        [PROFILE_SECTIONS.IDENTITY_DOCUMENTS]: ON,
        [PROFILE_SECTIONS.ADDRESSES]: ON
      });
      this.setClientProfile(res.data);

      return true;
    } catch (e) {
      return false;
    }
  }

  @Action
  cleanClient() {
    this.setClientTokens({
      [TOKENS.ACCESS_TOKEN]: '',
      [TOKENS.REFRESH_TOKEN]: ''
    });
    this.setClientProfile(NULL_CLIENT_PROFILE);
    this.setClientDisbursements(clientDisbursementMapInitialState());
  }

  @Action
  logOut() {
    const redirectTo = APPLICATION_ROUTES.LOGIN_PAGE;
    resetState();

    if (redirectTo === router.currentRoute.name) {
      return;
    }

    router.push({ name: redirectTo, params: { LOGOUT: LOGOUT } });
  }

  get livelinessVerificationFinished() {
    return this.profile.livelinessVerificationStatus !== VERIFICATION_STATUS.UNKNOWN;
  }

  get livelinessVerificationConfirmed() {
    return this.profile.livelinessVerificationStatus === VERIFICATION_STATUS.CONFIRMED;
  }

  get livelinessVerificationReject() {
    return this.profile.livelinessVerificationStatus === VERIFICATION_STATUS.REJECT;
  }

  get usernameVerificationFinished() {
    return this.profile.usernameVerificationStatus !== VERIFICATION_STATUS.UNKNOWN;
  }

  get usernameVerificationConfirmed() {
    return this.profile.usernameVerificationStatus === VERIFICATION_STATUS.CONFIRMED;
  }

  // Disbursements
  disbursements: DisbursementsResponseMap = clientDisbursementMapInitialState();

  get hasDisbursements() {
    return (
      this.disbursements[DISBURSEMENT_TYPE.BANK_ACCOUNT].length !== 0 ||
      this.disbursements[DISBURSEMENT_TYPE.BANK_CARD].length !== 0
    );
  }

  get isSigningMethodFilled(): boolean {
    return !!this.profile.agreementSigningMethod;
  }

  get currentBankAccount() {
    return this.disbursements[DISBURSEMENT_TYPE.BANK_ACCOUNT].filter(({ current }) => current)[0] || {};
  }

  get currentBankAccountNumber() {
    return this.currentBankAccount?.data?.bankAccountNumber;
  }

  get bankCardSelected() {
    const storedItem = localStorage.getItem('currentDisbursement');
    if (storedItem) {
      const storedCard = JSON.parse(storedItem) as DisbursementCard;
      const cards = this.disbursements[DISBURSEMENT_TYPE.BANK_CARD].filter(
        card => card.data.cardId === storedCard.data.cardId && card.data.userId === storedCard.data.userId
      );

      if (cards[0] ?? null) {
        return cards[0];
      }
    }

    const cards = this.disbursements[DISBURSEMENT_TYPE.BANK_CARD].filter(({ current }) => current);

    return cards[0] ?? null;
  }

  get bankCard() {
    const cards = this.disbursements[DISBURSEMENT_TYPE.BANK_CARD].filter(({ current }) => current);
    return cards.length ? [cards[0].data.cardHolder, cards[0].data.panMasked].join(', ') : '-';
  }

  @Mutation
  setClientDisbursements(disbursements: DisbursementsResponseMap) {
    this.disbursements = disbursements;
  }

  @Action
  async initProfileDisbursements(context: Payload = { force: false }) {
    if (this.accessToken === '') {
      return false;
    }

    if (this.disbursements[DISBURSEMENT_TYPE.BANK_CARD].length && !context.force) {
      return true;
    }

    return await this.loadProfileDisbursements();
  }

  @Action
  clearCurrentCardDisbursement() {
    localStorage.removeItem('currentDisbursement');
  }

  @Action
  storeCurrentCardDisbursement(disbursement: DisbursementCard) {
    localStorage.setItem('currentDisbursement', JSON.stringify(disbursement));
  }

  @Action
  async loadProfileDisbursements() {
    try {
      const { data: disbursementsResponse } = await getDisbursementType();

      const disbursements = disbursementsResponse.reduce<DisbursementsResponseMap>((map, disbursement) => {
        switch (disbursement.type) {
          case DISBURSEMENT_TYPE.BANK_CARD:
            map[DISBURSEMENT_TYPE.BANK_CARD].push(disbursement);
            break;
          case DISBURSEMENT_TYPE.BANK_ACCOUNT:
            map[DISBURSEMENT_TYPE.BANK_ACCOUNT].push(disbursement);
            break;
        }

        return map;
      }, clientDisbursementMapInitialState());

      this.setClientDisbursements(disbursements);

      return disbursementsResponse.filter(d => d).length > 0;
    } catch (e) {
      return false;
    }
  }
}

export const client = getModule(Client);
