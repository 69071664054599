import { NavigationGuard } from 'vue-router';
import { addStep, buildUiStepStateBySlugRoute } from '@/api/progress';
import { SEND_PROGRESS } from '@/router/routes';

export const sendProgress: NavigationGuard = async (to, from, next) => {
  if (from.name && to.params.SEND_PROGRESS && to.params.SEND_PROGRESS === SEND_PROGRESS) {
    const step = await buildUiStepStateBySlugRoute(from.name);
    if (step) {
      addStep(step);
    }
  }

  next();
};
